import {
    Box, Flex,Text,Button, Table,Thead,Tbody,Tr,Th,Td
  } from "@chakra-ui/react"
import { Link } from "react-router-dom"

import DocItem from "./DocItem"

const CompanyDocs=()=>{
    return(
        <Box p='10px'>
            <Box px='15px' id='profiles' mb='20px'>
                <Text fontSize='22px' fontWeight='500'>Company Details</Text>
                <span style={{backgroundColor:'#EDEDED',padding:'5px',borderRadius:'5px' }}>
                        <span style={{marginRight:'10px',fontWeight:'500' }}>POST</span>
                        <span>https://api.apedata.net/v1/company</span>
                </span>
            </Box>

            <Box id='people' px='15px'>
                <Box>
                    <Text fontSize='14px' fontWeight='500' >HEADERS</Text>
                    <Flex justifyContent='start' mb='50px'>
                        <Box w='90%' backgroundColor='#EBF1F5' minWidth='300px' 
                         pt='10px'>
                            <DocItem name='X-API-KEY' required={true} 
                                explanation='Your api key'
                            />
                        </Box>
                    </Flex>
                </Box>

                <Box>
                    <Text fontSize='14px' fontWeight='500' mb={5}>POST BODY</Text>
                    <Flex justifyContent='start' mb='50px'>
                        <Box w='90%' backgroundColor='#EBF1F5' minWidth='300px' pb='25px' pt='10px'>
                            <DocItem name='company_id' required={true} type='string'
                                explanation="The company's public ID on LinkedIn"
                            />
                            <DocItem name='bypass_cache' type='boolean'
                                explanation='If set to true, our 
                                temporary cache will be ignored, to retrieve fresh data from LinkedIn'
                                Default='false'
                            />
                            <DocItem name='company_jobs' type='boolean'
                                explanation={`When set to true, the first 10 job postings of the company on LinkedIn will be retrieved, if available.
                                \nTo retrieve more jobs, make use of the search jobs endpoint`}
                                Default='false'
                            />
                            <DocItem name='company_employees' type='boolean'
                                explanation={`When set to true, the first 10 employees and contractors of the company will be retrieved.
                                \nTo retrieve more employees, make use of the search people endpoint`}
                                Default='false'
                            />
                            <DocItem name='company_posts' type='boolean'
                                explanation={`When set to true, the first 10 posts and reposts made by the company page on LinkedIn will be retrieved. 
                                \nFor more posts, make use of the search posts endpoint`}
                                Default='false'
                            />
                        </Box>
                    </Flex>
                </Box>
                
            </Box>

        </Box>
    )
}

// To utilize these 
//                     endpoints, users are required to make an HTTP POST request and provide 
//                     the API key obtained from ApeData. Additionally, users must have a 
//                     positive credit balance in order to access this data.



export default CompanyDocs