import {
    Box, Flex,Text,Link,Button, Table,Thead,Tbody,Tr,Th,Td
  } from "@chakra-ui/react"


const Docs_Intro=()=>{
    return (
        <Box p='10px'>
            <Text fontSize='20px' fontWeight='600'>LinkedIn Public Data API Documentation (v1) </Text>
            <Text fontSize='14px'>
                Read the docs to get started with the integration. 
                You need an API key to use ApeData LinkedIn data API. 
                Don't have an API key yet? 
                <Link href='/app/keys' fontSize='14px' fontWeight='500'> Click here</Link> to get one.
            </Text>
{/* 
            <Text fontSize='18px' fontWeight='500'>Endpoints</Text>

            <Text m='3px'><Link href='#profiles#people' fontSize='14px'>1.Profile details</Link></Text>
            <Text m='3px'><Link href='?opened=profile&endpoint=company' fontSize='14px'>2. Company details</Link></Text>
            <Text m='3px'><Link href='?opened=search&endpoint=people' fontSize='14px'>3. Search people</Link></Text>
            <Text m='3px'><Link href='?opened=search&endpoint=company' fontSize='14px'>4. Search companies</Link></Text>
            <Text m='3px'><Link href='?opened=search&endpoint=posts' fontSize='14px'>5. Search posts</Link></Text>
            <Text m='3px'><Link href='?opened=search&endpoint=events' fontSize='14px'>6. Search  jobs</Link></Text>
            <Text m='3px'><Link href='?opened=search&endpoint=events' fontSize='14px'>7. Search  events</Link></Text>
            <Text m='3px'><Link href='?opened=search&endpoint=events' fontSize='14px'>8. Search  services</Link></Text>
            <Text m='3px'><Link href='?opened=search&endpoint=events' fontSize='14px'>9. People industries list</Link></Text>
            <Text m='3px'><Link href='?opened=search&endpoint=events' fontSize='14px'>10. Company industries list</Link></Text>
            <Text m='3px'><Link href='?opened=search&endpoint=events' fontSize='14px'>11. Service categories list</Link></Text> */}
        </Box>
    )
}

export default Docs_Intro