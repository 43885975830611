import {Box,Flex,Text,AlertDialog,Input,Link,CircularProgress,Button,
    FormControl,
    FormLabel
  } from "@chakra-ui/react"
  import Logo from "../components/Logo/Logo";


  import { useState } from "react";

  import './pages.css'



  const PasswordRequest=()=>{

    function isValidEmail(email) {
        const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    }

    const [emailRes,setEmailRes]=useState({show:false,error:true,message:''})
    const [showLoader,setShowLoader]=useState(false)

    const handleSubmit=(e)=>{
        e.preventDefault()
        const email=document.querySelector('input').value
        setEmailRes({show:false,error:true,message:''})
        if(isValidEmail(email)){
            // let url=`https://api.apedata.net/api/password/request`
            // let url=`http://localhost:3005/api/password/request`
            const url= process.env.REACT_APP_SERVER_HOST+'/api/password/request';
            setShowLoader(true)
            fetch(url,{
                method:'POST',
                headers:{'Content-Type':"application/json"},
                body:JSON.stringify({email})
            }).then(async res=>{
                setShowLoader(false)
                let response=await res.json()
                if(res.status==200){
                    setEmailRes({show:true,error:false,message:response.message})
                }else{
                    setEmailRes({show:true,error:true,message:response.message})
                }
                
            })
            .catch(err=>{
                setShowLoader(false)
                setEmailRes({show:true,error:true,message:err.message})
            })

        }else{
            setEmailRes({show:true,error:true,message:'Not a valid email address'})
        }

    }
    return(
        <Box  h='100vh' id='password_page'>
            <Box pt='20px' position='relative'>
                <Flex justifyContent='center'>
                    <Logo colored={true}/>
                </Flex>
                <Text color='#0F6972' fontSize='1.5rem' textAlign='center' m={0}>Reset your password</Text>
                <Flex justifyContent='center'>
                    <Flex w='40%' minW='300px'>
                        <Text textAlign='center' m={0} fontSize='14px'>
                        To reset your password, enter your email below and submit. 
                        An email will be sent to you with instructions about how to complete the process.
                        </Text>
                    </Flex>
                </Flex>
                

            {emailRes.error?<Flex justifyContent='center' mt='20px'>
                
                <FormControl  w='79%' >
                    <FormLabel fontSize='13px' fontWeight={500}>Email address</FormLabel>
                    <Input type='email' w='90%' h='30px' border='1px solid #B4D5D7'
                    borderRadius='5px' outline='none' required />

                    <Button mt='20px' p='5px' cursor='pointer' backgroundColor='#0F6972' h='36px' px='10px'
                    outline='none' type="submit" border='none' borderRadius='4px' color='white' onClick={handleSubmit}
                    >Reset Password</Button>
                
                </FormControl>
            </Flex>:null}

            {showLoader?<Box position='absolute' top='50%' right='50%'>
                <CircularProgress isIndeterminate color='#0F6972' />
            </Box>:null}



                {emailRes.show?<Flex justifyContent='center' mt='20px'>
                    <Flex alignItems='center' 
                    w='79%' backgroundColor={emailRes.error?'#FAD4D5':'#AFFFE2'} h='80px' borderRadius='10px'
                    border={emailRes.error?'1px solid #F0A2A5':'1px solid #168B62' }
                    px='10px'>
                            
                            {emailRes.error?
                                <span style={{color:'#F57F80',marginRight:'10px',fontSize:'20px',cursor:'pointer'}}
                                onClick={()=>setEmailRes({show:false,error:true,message:''})}>
                            &#10006;
                            </span> :
                            <span style={{color:'#12827C',marginRight:'10px',fontSize:'20px'}}>
                            &#10004;
                            </span> }
                            <Text fontSize='14px'>
                            {emailRes.message}
                            </Text>
                    </Flex>
                </Flex>:null}
            

            </Box>

            <Box backgroundColor='#0F6972'>

            </Box>
            

           
            
        </Box>
    )
  }

  export default PasswordRequest;