import {Box,Flex,Text,Input,Link,CircularProgress,Button } from "@chakra-ui/react"

  import { FiTrash2  ,FiCopy,FiCheck  } from "react-icons/fi";
  
  import Index from '../components/app/Index'
  import Playground from '../components/app/Playground'
  import KeysPart from '../components/app/Keys'
  import { useState,useEffect } from "react";
  import '../components/app/app.css'
  import './pages.css'

const Keys=()=>{
    const [allApiKeys,setAllApiKeys]=useState([])
    const [showLoader,setShowLoader]=useState(false)
    const [confirmBox,setConfirmBox]=useState({show:false,key:'',copied:false})
    const [confirmDelete,setConfirmDelete]=useState({show:false,currKey:''})
    let webToken
    const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Check if the cookie starts with the specified name
            if (cookie.startsWith('webToken=')) {
                webToken=cookie.substring('webToken='.length)
            }
        }
        
    const setDeleteValues=(evt)=>{
        evt.preventDefault()
        const key=evt.target.id
        console.log(key);
        setConfirmDelete({...confirmDelete,show:true,currKey:key})
    }
    const getAllKeys=()=>{
            return new Promise(async(resolve, reject) => {
                // let url=`http://localhost:3005/api/data/config`
                // let url=`https://api.apedata.net/api/data/config`
                const url= process.env.REACT_APP_SERVER_HOST+'/api/data/config';
                
                try {
                    fetch(url,{
                        method:'GET',
                        headers:{
                            "Content-Type":'application/json',
                            "authorization":`Bearer ${webToken}`}
                    }).then(async res=>{
                        let response=await res.json()
                        const theKeys=response.data.apiKeys
                        setAllApiKeys(theKeys)
                        resolve(theKeys);
                    })
                } catch (error) {
                    
                }
            })
        }
    
  

    const handleNewKeyCreation=(e)=>{
        e.preventDefault()
        const keyName=document.querySelector('#keyName').value
        const createdAt=new Date().getTime()
        // let url=`https://api.apedata.net/api/keys/new`

        if(keyName && keyName.length>1){
            // let url=`http://localhost:3005/api/keys/new`
            let url=`https://api.apedata.net/api/keys/new`
            hideCreateAlert()
            setShowLoader(true)
            try {
                fetch(url,{
                    method:'POST',
                    headers:{
                        "Content-Type":'application/json',
                        "authorization":`Bearer ${webToken}`},
                    body:JSON.stringify({name:keyName,createdAt})
                })
                .then(async(response)=>{
                    let res=await response.json()
                    console.log(res);
                    getAllKeys().then((rep)=>{
                        setShowLoader(false)
                        setConfirmBox({show:true,key:res.key_val})
                    })
                    
                })
                
            } catch (error) {
                
            }
            
        }else{
            console.log('Not a valid keyname');
        }

    }

    const copyAction=()=>{
        const copyBtn=document.querySelector('#copyBtn')
        const copyInput=document.querySelector('#copyInput')
        console.log(copyInput.value);
        navigator.clipboard.writeText(copyInput.value)
        setConfirmBox({...confirmBox,copied:true})
        setTimeout(() => {
            setConfirmBox({...confirmBox,copied:false})
        }, 1000);
    }

    const deleteKey=(e)=>{
        e.preventDefault()
        let key=e.target.id
        setConfirmDelete({show:false,currKey:''})
        setShowLoader(true)
        let url=`https://api.apedata.net/api/keys/remove`
        // let url=`http://localhost:3005/api/keys/remove`
        try {
            fetch(url,{
                method:'POST',
                headers:{
                    "Content-Type":'application/json',
                    "authorization":`Bearer ${webToken}`},
                body:JSON.stringify({key_val:key})
            })
            .then(async(response)=>{
                getAllKeys().then(res=>{
                    setShowLoader(false)
                })
            })
            
        } catch (error) {
            
        }
    }

    function formatDate(epoch) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        if(epoch===0){
            return 'Never'
        }else{
            const date = new Date(epoch); // Convert seconds to milliseconds
      
            const month = months[date.getMonth()];
            const day = date.getDate();
            const year = date.getFullYear();
        
            return `${month} ${day}, ${year}`;
        }
        
      }

    function formatSecret(secret_key){
        if(secret_key){
            let len=secret_key.length
        let first_three=secret_key[0] + secret_key[1]+ secret_key[2]
        let last_three=secret_key[len-3]+secret_key[len-2]+secret_key[len-1]

        let fnal=first_three+'...'+last_three

        return(fnal)
        }
        
    }
    
    const hideCreateAlert=()=>{
        const createAlertParent=document.querySelector('#createAlertParent')
        createAlertParent.style.display='none'
    }
    const showCreateAlert=()=>{
        const createAlertParent=document.querySelector('#createAlertParent')
        createAlertParent.style.display='flex'
    }

    useEffect(()=>{
        const createAlertParent=document.querySelector('#createAlertParent')
        createAlertParent.style.display='none'

        
        const showAlertBtn=document.querySelector('#showAlertBtn')
        

        document.addEventListener('click', function(event) {
            // Check if the clicked element is not within the div
            const alertBox=document.querySelector('#alertBox')
            if (!alertBox.contains(event.target) && !showAlertBtn.contains(event.target)) {
                hideCreateAlert()
            }

            const copyBox=document.querySelector('#copyBox')

            if(copyBox){
                if(!copyBox.contains(event.target)){
                    setConfirmBox({show:false,key:'',copied:false})
                }
            }
            

        });
        getAllKeys()
    },[])
    return(
        <Box  minH='500px'>
            <Index selected='keys' title='API KEYS'
            mainPart={
            <Box position='relative'>
                <Box h='80px' borderBottom='1px solid grey' display='flex' alignItems='center' px='20px'>
                    <Text fontSize='15px' fontWeight='600'>API KEYS</Text>
                </Box>
                <Box p='10px' px='20px'>

                    <Text fontSize='14px'>Your secret API keys are listed below</Text>
                    <Text fontSize='14px'>
                        Do not share your API key with others, or expose it in the browser or 
                        other client-side code
                    </Text>
                    <Text fontSize='14px'>You can see usage per API key on the 
                        <Link href='/app/usage' textDecoration='none'> usage page.</Link>
                    </Text>
                    

                </Box>
                <Flex  position='absolute' top='50%' right='50%' left='50%' justifyContent='center' id='createAlertParent'>
                    <Box     minW='320px' w='60%' position='relative' bottom='100px' 
                     p='20px' borderRadius='4px' 
                    id='alertBox' zIndex='999'>
                        <Text m={0} fontWeight={500}>Create new API key</Text>
                        <label style={{fontSize:'12px',fontWeight:500}}>Name*</label><br/>
                        <form onSubmit={handleNewKeyCreation}>
                            <Input required w='80%' h='30px' id='keyName'>

                            </Input>
                            <Flex justifyContent='flex-start' alignItems='center' mt='20px'>

                            <Button mr='10px' border='none' outline='none' onClick={hideCreateAlert}
                            cursor='pointer' p='10px' h='30px' borderRadius='4px'>
                                Cancel
                            </Button>

                            <Button mr='10px' type='submit' border='none' outline='none' p='10px'
                            cursor='pointer' backgroundColor='#1a7f64' color='white' h='30px' borderRadius='4px'>
                                Create API Key
                            </Button>
                            </Flex>

                        </form>
                        
                    </Box>
                </Flex>

                {confirmBox.show?<Flex  position='absolute' top='50%' right='50%' left='50%' justifyContent='center' id='saveKeyParent'>
                    <Box     minW='350px' w='60%' position='relative' bottom='100px' p='20px' borderRadius='4px' 
                    id='copyBox' zIndex='999'>
                        <Text m={1} fontWeight={700} fontSize='16px'>Save Your Key</Text>
                        <Text m={4} fontSize='13px'>Please save this key somewhere safe and accessible</Text>
                        <Text m={4} fontSize='13px' color='#1A7F64' fontWeight='600'>
                        You won't be able to view it again through your account.
                        </Text>
                        <Text m={3} fontSize='13px'>
                        If you lose this key, you'll have to make a new one
                        </Text>

                        <Flex mt='10px' alignItems='center'>
                            
                            <Input w='70%' h='20px' p='5px' mr='10px' readOnly
                            value={confirmBox.key} id='copyInput'/>

                            {confirmBox.copied?<span style={{fontSize:'14px',backgroundColor:'#1a7f64',borderRadius:'5px',
                            color:'white',padding:'5px',display:'flex',alignItems:'center',cursor:'pointer'}}
                            onClick={copyAction} id='copyBtn'>
                            <FiCheck size="20px"/> Copied
                            </span>
                            :
                            <span style={{fontSize:'14px',backgroundColor:'#1a7f64',borderRadius:'5px',
                            color:'white',padding:'5px',display:'flex',alignItems:'center',cursor:'pointer'}}
                            onClick={copyAction} id='copyBtn'>
                            <FiCopy size="20px"/>Copy
                            </span>}
                            
                        </Flex>
                        
                        
                    </Box>
                </Flex>:null}

                {showLoader?<Box position='absolute' top='50%' right='50%'>
                <CircularProgress isIndeterminate color='#0F6972' />
            </Box>:null}

            {confirmDelete.show?<Flex  position='absolute' top='50%' right='50%' left='50%' 
            justifyContent='center' id='saveKeyParent'>
                <Box     minW='350px' w='60%' position='relative' bottom='100px' p='20px' borderRadius='4px' 
                    id='copyBox' zIndex='999'>
                            <Text m={5} fontWeight={700} fontSize='16px'>Delete API Key</Text>
                            <Flex justifyContent='flex-start' alignItems='center' mt='20px'>

                            <Button mr='10px' border='none' outline='none' 
                            cursor='pointer' p='10px' h='30px' borderRadius='4px'
                            onClick={()=>{setConfirmDelete({show:false,currKey:''})}}>
                                Cancel
                            </Button>

                            <Button mr='10px' type='submit' border='none' outline='none' p='10px'
                            cursor='pointer' backgroundColor='#1a7f64' color='white' h='30px' borderRadius='4px'
                            onClick={deleteKey} id={confirmDelete.currKey}>
                                Delete API Key
                            </Button>
                            </Flex>
                    </Box>

            </Flex>:null}
                

                <Box p='10px' overflow='auto' px='20px'>

                    <Box id='tableBox' minW='500px' w='90%' >

                        <Box display='flex'  scroll='auto' borderBottom='#C0C0C0 1px solid' w='100%'>
                                <Box  id='name' w='20%' minW='70px' mx='2px'fontSize='13px' fontWeight='500'>NAME</Box>
                                <Box  id='secretKey' w='30%'  minW='80px' mx='2px' fontSize='13px' fontWeight='500'>SECRET KEY</Box>
                                <Box  id='created' w='15%' minW='50px' mx='5px' fontSize='13px' fontWeight='500'>CREATED</Box>
                                <Box  id='lastUsed' w='15%'  minW='100px' mx='5px' fontSize='13px' fontWeight='500'>LAST USED</Box>
                                <Box  id='actions'w='8%' minW='50px' mx='2px' fontSize='13px' fontWeight='500'>ACTIONS</Box>
                        </Box>
                        {allApiKeys[0]?allApiKeys.map(apiKey => (
                            <Flex  scroll='auto' borderBottom='#C0C0C0 1px solid' w='100%' h='30px' 
                            alignItems='center' key={apiKey.key_val}>
                                <Box  id='name' w='20%' minW='70px' mx='2px'fontSize='13px' fontWeight='400'>
                                    {apiKey.name}
                                </Box>
                                <Box  id='secretKey' w='30%'  minW='80px' mx='2px' fontSize='14px' fontWeight='400'>
                                    {formatSecret(apiKey.key_val)}
                                </Box>
                                <Box  id='created' w='15%' minW='50px' mx='5px' fontSize='13px' fontWeight='400'>
                                    {formatDate(apiKey.created)}
                                </Box>
                                <Box  id='lastUsed' w='15%'  minW='100px' mx='5px' fontSize='13px' fontWeight='400'>
                                    {formatDate(apiKey.lastUsed)}
                                </Box>
                                <Flex  id='actions'w='8%' minW='50px' mx='2px' fontSize='13px' 
                                fontWeight='500' justifyContent='center'>
                                    <FiTrash2  id={apiKey.key_val} onClick={setDeleteValues} 
                                    style={{cursor:'pointer'}} title="delete"/>
                                </Flex>
                            </Flex>
                        )):null}
                       

                    </Box>

                    <Button outline='none' border='none'cursor='pointer' h='30px'
                        borderRadius='2px' px='10px' color='white' backgroundColor='grey' 
                        marginTop='10px' onClick={showCreateAlert } id='showAlertBtn'>
                        <Text fontSize='20px' mr='3px'>+</Text>
                        Create new API key 
                        </Button>
                    
                </Box>
                
            </Box>
            }>

            </Index>
            
        </Box>
    )
}

export default Keys;