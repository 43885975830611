import {
    Box,Text,Select ,Flex,Input,Button
  } from "@chakra-ui/react"

  import Navbar from "../components/Navbar/Navbar";
  import FirstSection from "../components/Home/FirstSection";
  import SecondSection from "../components/Home/SecondSection";
  import ThirdSection from "../components/Home/ThirdSection";
  import Footer from "../components/Footer/Footer";
  import Index from '../components/app/Index'
  import { useEffect,useState } from "react";

const Playground=()=>{

    const [endpoint,setEndpoint]=useState('profile')
    const handleEndpointChange=(e)=>{
        setEndpoint(e.target.value);
    }
    return(
        <Box>
        <Index selected='playground' mainPart={
            <Box>
                <Box h='80px' borderBottom='1px solid grey' display='flex' 
                alignItems='center' px='20px' >
                    <Text fontSize='15px' fontWeight='600'>PLAYGROUND</Text>
                </Box>
                <Box p='10px'>
                    <Box  borderRadius='5px'   px='10px' overflowX='auto'>
                        <Flex alignItems='center'>
                            <Text marginRight='10px' fontWeight='500'>API Endpoint</Text>
                            <select placeholder='select endpoint' onChange={handleEndpointChange}
                            style={{height:'25px',width:'fit-content',minWidth:'100px',cursor:'pointer'}}>
                                <option value='profile' selected>Fetch Profile</option>
                                <option value='company'>Fetch Company</option>
                                <option value='search_profile'>Search Profiles</option>
                                <option value='search_company'>Search Company</option>
                            </select>

                        </Flex>

                        <Flex alignItems='center'>
                            <Text marginRight='10px' fontWeight='600' color='#4988C6'>POST</Text>
                            <Text color='#505760' fontWeight='500'>
                            {
                                endpoint==='profile'?
                                process.env.REACT_APP_SERVER_HOST+'/v1/profile':
                                endpoint==='company'?
                                process.env.REACT_APP_SERVER_HOST+'/v1/company':
                                endpoint==='search_profile'?
                                process.env.REACT_APP_SERVER_HOST+'/v1/search/profile':
                                endpoint==='search_company'?
                                process.env.REACT_APP_SERVER_HOST+'/v1/search/company':null
                            }
                            
                            </Text>
                        </Flex>

                        <Text color='#E47911' m='0px' fontWeight='500'>Headers</Text>
                        <Box border='1px solid grey' p='3px' borderRadius='3px' w='60%' minW='450px'>
                            <Flex alignItems='center' m='0px'>
                                <Text m='0px' mr='10px' my='5px' fontSize='13px' fontWeight='500'>Content-Type :</Text>
                                <Text fontSize='13px' m='0px' fontWeight='500'>application/json</Text>
                            </Flex>
                            <Flex alignItems='center'>
                                <Text m='0px' mr='10px' my='5px'fontSize='13px' fontWeight='500'>
                                X-API-KEY &nbsp; &nbsp; &nbsp; :</Text>
                                <Input placeholder="Paste Your API Key  Here" mr='10px' minW='200px' h='20px'/>
                                <a><button>Get A Key</button></a>
                            </Flex>
                        </Box>

                        <Text m='0px'color='#E47911' mt='10px' fontWeight='500'>Body</Text>
                        <Box border='1px solid grey' px='2px' borderRadius='3px' w='60%' minW='450px'>
                            <Text m='0px' fontSize='13px' fontWeight='500'>&#123;</Text>
                            <Flex m='0px' ml='20px' alignItems='center'>
                                <Text m='0px' fontSize='13px' fontWeight='500'>"profile_id" :</Text>
                                <Input placeholder="williamhgates" value='williamhgates' h='15px' ml='10px'/>,
                            </Flex>
                            <Flex m='0px'ml='20px' alignItems='center'>
                                <Text m='0px' fontSize='13px' fontWeight='500'>"bypass_cache" :</Text>
                                <select fontSize='13px' fontWeight='500'>
                                    <option value={true} selected>True</option>
                                    <option value={false}>False</option>
                                </select>,
                            </Flex>
                            <Text m='0px'>&#125;</Text>
                        </Box>

                        <Button mt='20px' cursor='pointer' outline='none' border='none'
                        backgroundColor='#E47911' h='30px' color='white'
                        >Make Request</Button>

                        <Text color='#E47911' mt='30px' fontWeight='500'>Response</Text>
                        
                        
                    </Box>
                </Box>
            </Box>
            }/>
        </Box>
    )
}

export default Playground;