import {
    Box,
    Text,
    Image,
    Link,
    useDisclosure ,
    Flex,
    Button
    
  } from "@chakra-ui/react"

  import Navbar from "../components/Navbar/Navbar";
  import Footer from "../components/Footer/Footer";

const Tos=()=>{
    return(
        <Box>
        <Navbar light={true}/>
        <Text textAlign='center' fontWeight='700' fontSize='3rem' m='0px' color='#0F6972'>
        Terms of Service</Text>
        <Box px='100px' pb='100px'>
            <Text fontWeight='500' fontSize='1.8rem' >Introduction</Text>
           <Text>
            Welcome to Apedata.net. This is an API service website that is owned by ApeData Inc. . 
            For the purpose of this website, the company is doing business as apedata.net under ApeData Inc. 
            This document contains the terms of service that govern the relationship between the owner of the website and all the users.
           </Text>

            <Text fontWeight='500' fontSize='1.8rem' >Definitions</Text>
            <Text>For the purpose of this agreement, the terms:</Text>
            <Text>“API” refers to an Application Programming Interface developed by Apedata</Text>
            <Text>
             “API User” or “You” refers to anyone who visits the site for the purpose of browsing or 
             creating an account to enjoy the services we offer. Browsing as per the provisions of this 
             clause refers to the act of skimming or going through the website to have an idea 
             of the services we offer.
            </Text>
            <Text>“API Customer” refers to an individual or legal entity that creates an account on 
            the site by providing personal information and pays to enjoy the API services we offer
            </Text>

            <Text>
            “Proprietor”, “We” , “Our” , “Us” refers to ApeDta Inc. that renders its services as apedata.net.
            </Text>

            <Text>
                “Site” or “Website” refers to <Link href='https://apedata.net/'>apedata.net</Link>
            </Text>
            <Text>“Webpage” refers to the page on the site containing these terms of service.</Text>
            
            <Text fontWeight='500' fontSize='1.8rem' >Agreement</Text>
            <Text>
            By using or visiting the site and enjoying the services offered, you expressly 
            agree to be legally bound by the terms found herein on this webpage. Consequently, you are 
            expected to obey the provisions published herein and all legal dispositions that are 
            applicable to the user situation.
            </Text>
            <Text>
            If the user is a legal entity or individual who allows employees or subcontractors to 
            access the platform using their account, you need to instruct them to read these terms 
            of service so as to comply with them. An assumption will be made that they have read the 
            provisions herein once they start using the site.
            </Text>
            <Text>
            In case you do not agree or accept any of the clauses that govern the use of this site, 
            do not use it. Read these guidelines carefully.
            </Text>
            <Text>
            Please also note that we reserve the right to alter this page and any other information 
            published on the site without giving you prior notice. Using this site after we modify the 
            terms of service is an indication that you accept the changes made and will be legally bound
             by them.
            </Text>



            <Text fontWeight='500' fontSize='1.8rem' >Services offered on this site</Text>
            <Text>
            We offer API services by feeding data from a third party source (LinkedIn) to our API 
            customers. These services are prepaid. The API customers on this site can generate 
            LinkedIn public data through our API by sending HTTP requests
            </Text>
            <Text>
            The public data includes information like company profiles, contact details (if public) 
            and company’s list of employees. Our API customers can also find regular or company LinkedIn 
            profiles with the help of our filters.
            </Text>
            <Text>
            Please note that we only generate public data and not private LinkedIn information. 
            As such, when using our services, you can only generate such information that LinkedIn
             account holders have allowed to be public.
            </Text>

            <Text fontWeight='500' fontSize='1.8rem' >Account creation</Text>
            <Text>
            To create an account on the site, you will need to click on the “Sign Up” button found on 
            the homepage of our site and provide information like full name, email address and credit 
            card details. We collect this personal data.
            </Text>

            <Text fontWeight='500' fontSize='1.8rem' >Obligations or responsibilities of account holders</Text>
            <Text>
            As an account holder, you are in charge of all the activities that take place in your account. You should:
            </Text>
            <ul>
                <li>Provide accurate and valid personal information that will be used to create your profile.</li>
                <li>Ensure that personal information provided is always up-to-date.</li>   
                <li>Manage your account in a way that doesn’t affect our site or other accounts.</li>
            </ul>
            <Text>As an API customer, you should not:</Text>
            <ul>
                <li>Disrupt or interfere with other accounts on the site.</li>
                <li>Allow a third party to use your account without instructing them to read this agreement.</li>   
                <li>Use the site in a manner that violates any local or international laws .</li>
                <li>Use a device that interferes with the proper functioning of this site.</li>
            </ul>

            <Text fontWeight='500' fontSize='1.8rem' >Credits</Text>
            <Text>
            To make an API request, an API customer needs credits. The services we offer 
            are prepaid services. We don’t offer a recurring subscription. As such, payment needs to 
            be made first before being able to make API calls or requests to our service to get 
            LinkedIn public data or LinkedIn profiles.
            </Text>
            <Text>
            After signing up, you will receive test credits to try our API. Upon depletion of the 
            test credits, you can start purchasing additional credits. More details about the cost 
            per credit can be found on our website in the pricing webpage.
            </Text>

            <Text fontWeight='500' fontSize='1.8rem' >Payment</Text>
            <Text>
            You will make payments using the credit card that you added during the account 
            signing up process. Credit card payments are processed by Stripe. As an API customer, 
            ensure that your credit card details are always up-to-date to facilitate a smooth 
            transaction.
            </Text>


            <Text fontWeight='500' fontSize='1.8rem' >Refund</Text>
            <Text>
            Customers have a right to request for a refund. However, please note that we only offer 
            refunds for unused credits upon the request of the API customer.
            </Text>

            <Text fontWeight='500' fontSize='1.8rem' >Personal information and data protection</Text>
            <Text>
            During creation of your account, you provide personal information like your full name, 
            email address and credit card details. We collect this personal information. 
            Our company values your privacy and we do not share your personal information or 
            private data with any third party. 
            Additionally, we don’t disclose any information related to your usage of our website.
            </Text>

            <Text fontWeight='500' fontSize='1.8rem' >Limited liability</Text>
            <Text>
            Apedata (https://apedata.net) is created by professional web developers. 
            The site has been properly set up and can be accessed by users from different parts of the 
            world. We will not be responsible for any problem you experience when visiting any
             webpages or using this site.
              However, you can inform us about any challenges that you have experienced when
               using the site. This information will help us improve the site to ensure that it is
                user friendly. We will not be responsible for any damages, injuries or losses 
                incurred as a result of the use of this site and its content.
            </Text>
            <Text>
            Whereas we strive to prevent one member from interfering with the accounts of other 
            members by restricting or stopping their access to our website, we are not responsible for 
            their acts or omissions and cannot be held liable if one member incurs losses, damages or
             injuries because of another member.
            </Text>
           <Text>
            We reserve the right to alter, remove or, if necessary, discontinue the services offered 
            on the website at any time without prior notice. As such, we do not guarantee the 
            availability of any content or webpage. You cannot hold us liable for the absence of 
            any information that was available on the site but has been removed.
           </Text>

            <Text fontWeight='500' fontSize='1.8rem' >Non-competition agreement</Text>
            <Text>
            By using our API services, you agree not to directly or indirectly engage in a 
            similar business, develop competing products, or solicit our clients for the duration
             of your use of our services and for five years thereafter.
             </Text>
            

            <Text fontWeight='500' fontSize='1.8rem' >Intellectual property</Text>
            <Text>
            All the materials and content on the site 
            (including but not limited to website name, website logo, downloadable samples, 
            introductory video, text, graphics, images and websites software) belong to Apedata Inc.
             and are protected by copyright laws, trademark laws and other intellectual property laws.
            </Text>
            <Text>
            You are not allowed to:
            </Text>
            <ul>
                <li>Copy or adapt the website’s software</li>
                <li>Copy or adapt or alter the website logo</li>
                <li>Copy, alter, translate or republish any material including but not limited 
                to text and images available on this site without prior authorization of the proprietor.
                </li>
                <li>Allow third parties to copy, alter, translate or republish any content using your profile.</li>
                <li>Delete the copyright or other proprietary rights notice from any content.</li>
                <li>Print and sell the downloadable samples without prior authorization of the proprietor</li>
                <li>Republish the introductory video on another platform without prior authorization</li>
            </ul>

            <Text>
            If you violate or infringe our intellectual property rights by carrying out the 
            unauthorized activities, we may:
            </Text>
            <ul>
                <li>Ask you to delete the content where you have republished it.</li>
                <li>Ask you to stop selling our content .</li>
                <li>Ask you to compensate us .</li>
                <li>Take legal action against you</li>
            </ul>

            <Text fontWeight='500' fontSize='1.8rem' >Breach of terms of service</Text>
            <Text>A user violates these terms of service by failing to comply with any of the provisions 
            stipulated herein.</Text>
            <Text>
            Upon finding that an API user has breached any of the provisions on this webpage, 
            the proprietor of this site may carry out any or all of the following activities:
            </Text>
            <ul>
                <li>Suspend your access to the site.</li>
                <li>Request the internet provider to permanently block you from using this site .</li>
                <li>Take legal action under either criminal or civil law against you.</li>
            </ul>

            <Text fontWeight='500' fontSize='1.8rem' >Termination of your account</Text>
            <Text>There are two main grounds that attract the termination of your account:</Text>
            <ul>
                <li>If you request us to permanently delete your account</li>
                <li>Breach of any provision stated in this agreement</li>
            </ul>
            <Text>
            We reserve the right to terminate your access to this site at our discretion without any 
            prior notice. If required by law, we may also terminate your access to our site.
            </Text>

            <Text fontWeight='500' fontSize='1.8rem' >Applicable Law</Text>
            <Text>
            This site can be accessed by people from different countries in the world. Since the laws 
            may differ, the terms of service agreement shall be governed by the laws of Delaware,
             United States when resolving any dispute relating to this website.
            </Text>

            <Text fontWeight='500' fontSize='1.8rem' >Severance</Text>
            <Text>
            In a situation where a court or any other competent authority finds and declares a 
            provision or clause on this webpage as invalid or unenforceable, that provision shall 
            be deemed deleted. However, the remaining parts or provisions will be valid and enforceable.
            </Text>

            <Text fontWeight='500' fontSize='1.8rem' >Contact Information</Text>
            <Text>
            You can contact us at support@apedata.net
            </Text>

        </Box>
        <Footer/>

        </Box>
    )
}


export default Tos