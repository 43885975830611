import {
    Box,
    Text,
    Image,
    Link,
    useDisclosure ,
    Flex,
    Button
    
  } from "@chakra-ui/react"

  import Navbar from "../components/Navbar/Navbar";
  import Footer from "../components/Footer/Footer";

const Privacy=()=>{
    return(
        <Box>
        <Navbar light={true}/>
        <Text textAlign='center' fontWeight='700' fontSize='3rem' m='0px' color='#0F6972'>
        Privacy Policy</Text>
        <Box px='100px' pb='100px'>
            <Text fontWeight='500' fontSize='1.8rem' >Introduction</Text>
            <ul>
                <li>This Privacy Policy informs users about how we use the information provided when using ApeData.</li>
                <li>By using our services, you agree to the collection and use of your information in accordance with this Privacy Policy.</li>
            </ul>
            <Text fontWeight='500' fontSize='1.8rem' >Information Collected</Text>
            <ul>
                <li>We collect personal information, email addresses and user set password during user registration.</li>
                <li>We do not use cookies to collect user information.</li>   
                <li>Usage data: We may collect information about how you access and 
                use the Service, including your usage times, api keys and credit usage</li> 
            </ul>
            <Text fontWeight='500' fontSize='1.8rem' >How Information is Used</Text>
            <ul>
                <li>We collect email addresses for account access </li>
                <li>We use your email address for communicating with you, including responding to your inquiries, requests, and feedback</li>
                <li>Periodically, we may send updates about our API service or promotional emails about our products.</li>   
                <li>We track usage and credits to provide authorized access to the service</li> 
            </ul>
            <Text fontWeight='500' fontSize='1.8rem' >Information Sharing and Disclosure</Text>
            <ul>
                <li>We share customer names and email addresses with Stripe, our payment gateway.</li>
                <li>When users provide card details, we use Stripe SDK to store card information with Stripe.</li>   
                <li>Compliance with Laws: We may disclose your personal information if required to do so by law or in response to valid legal requests, such as court orders or subpoenas.</li>
                <li>We do not disclose our customers publicly</li> 
            </ul>

            <Text fontWeight='500' fontSize='1.8rem' >Data Retention</Text>
            <ul>
                <li>We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</li>
                <li>We store user account passwords in one-way hashes, ensuring their protection.</li>   
                <li>Card details are securely stored with Stripe.</li>
            </ul>

            <Text fontWeight='500' fontSize='1.8rem' >User Rights</Text>
            <ul>
                <li>Users have the right to access, correct, or delete their account information</li>
                <li>Users can request the deletion of their account and associated information by contacting us.</li>   
                <li>Card details are securely stored with Stripe.</li>
            </ul>

            <Text fontWeight='500' fontSize='1.8rem' >Changes to the Privacy Policy</Text>
            <ul>
                <li>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements</li>
                <li>We will notify you of any material changes by posting the new Privacy Policy on this page.</li>   
                <li>Card details are securely stored with Stripe.</li>
            </ul>

            <Text fontWeight='500' fontSize='1.8rem' >Contact Us</Text>
            <ul>
                <li>If you have any questions or concerns about this Privacy Policy or our handling of your personal information, please contact us at support@apedata.net</li>
                
            </ul>
            <Text>Please note that this Privacy Policy is subject to change without prior notice. We encourage you to review it periodically to stay informed about our privacy practices.</Text>

        </Box>

        <Footer/>
        

        </Box>
    )
}


export default Privacy