import {
    Box,
    Flex,
    Text,
    Input,
    Link,
    Button,
    CircularProgress 
  } from "@chakra-ui/react"
  import Logo from "../Logo/Logo";
  import './app.css'
  import { useState } from "react";
  import { FiEyeOff,FiEye } from "react-icons/fi";
import { redirect } from "react-router-dom";

const Login=()=>{
    const [errors,setErrors]=useState({})
    const [showLoader,setShowLoader]=useState(false)

    const [showPwd,setShowPwd]=useState(false)

    function isValidEmail(email) {
        const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    }
    let search=new URL(window.location.href).search
    const searchParams = new URLSearchParams(search);
    let to=searchParams.get('redirect_to')
  
    const handleSubmit=(e)=>{
        e.preventDefault()
        let pass=document.querySelector('#pwd').value
        let email=document.querySelector('#email').value

        if(!email || email.length<1){
            return setErrors({...errors,email:'Field is required'})
        }else{
            if(!pass || pass.length<1){
                return setErrors({...errors,pwd:'Field is required'})
            }else{
                if(isValidEmail(email)){
                    // let url=`http://localhost:3005/auth/login`
                    // let url=`https://api.apedata.net/auth/login`
                    const url= process.env.REACT_APP_SERVER_HOST+'/auth/login';
                    try {
                        setShowLoader(true)
                        setErrors({})
                        fetch(url,{
                            method:'POST',
                            headers:{
                                "Content-Type":"application/json"
                            },
                            body:JSON.stringify({email,password:pass})
                        })
                        .then(async res=>{
                            setShowLoader(false)
                            let response= await res.json()
                            if(res.status===200){
                                const {webToken}=response
                                document.cookie = `webToken=${webToken}; max-age=${3 * 60 * 60};`
                                if(search.length>1){
                                    window.location.href=to
                                }else{
                                    window.location.href = '/app/dashboard';
                                }
                               
                            }else{
                                return setErrors({email:response.message,pwd:response.message})
                            }
                            
                        })
                        .catch(error=>{
                            setShowLoader(false)
                            return setErrors({email:`${error.message}. Please try again later`})
                        })
                        
                    } catch (error) {
                        setShowLoader(false)
                        return setErrors({email:'Network error. Please try again'})
                    }
                    

                }else{
                    return setErrors({...errors,email:'Please provide a valid email address'})
                }
            }
        }
    }
    return(
        <Flex justifyContent='center' alignItems='center'  h='100vh'  id='login' py='10px'
        backgroundColor='#e7f0f0'>
            {showLoader?<Box position='absolute' top='50%' right='50%' zIndex='999'>
                <CircularProgress isIndeterminate color='#0F6972' />
            </Box>:null}
            <Box minW='300px' w='40%' height='fit-content' py='50px'
            borderRadius='5px'  backgroundColor='white'>
                <Flex justifyContent='center' alignItems='center'>
                    <Logo colored={true} bg={true}/>
                </Flex>
                <Text textAlign='center' fontSize='12px' m='5px' fontWeight='400' marginBottom='10px' >
                    Don't have an account? 
                    <Link href={`/app/register${search} `}textDecoration='none' 
                    fontWeight='500' color='#F7941D'> Sign Up</Link>
                </Text>
                <Flex justifyContent='center' alignItems='center'>
                    <Flex variant='filled'justifyContent='center' alignItems='center' my='5px' w='80%'>
                        <Input placeholder="Email" variant='filled' w='100%' h='30px' p='5px' id='email'
                        outline='none' border={errors.email?'1px solid #E53E3E':'2px solid #EDEFF2'} 
                        borderRadius='6px' />
                    </Flex>
                </Flex>
                
                    {errors.email?
                        <Flex justifyContent='center' alignItems='center' h='15px'>
                            <Text w='82%' fontSize='12px' fontWeight={500}color='#E53E3E'>{errors.email}</Text>
                        </Flex>:
                        null}
                    
             
                <Flex variant='filled'justifyContent='center' alignItems='center'  my='5px' >
                    <Flex justifyContent='center' alignItems='center' position='relative'
                    border={errors.pwd?'1px solid #E53E3E':'2px solid #EDEFF2'}  w='80%' borderRadius='6px'>
                        <Input placeholder="Password" variant='filled' w='100%' h='30px' p='5px' id='pwd'
                        outline='none' border='none'
                         type={showPwd?"text":"password"}/>

                        <span position='absolute' right='15px' style={{cursor:'pointer',padding:'3px'}}
                        onClick={()=>{setShowPwd(!showPwd)}}>
                            {showPwd?<FiEye/>:<FiEyeOff/>}
                            
                        </span>
                    </Flex>
                
                    
                </Flex>
                {errors.pwd?
                        <Flex justifyContent='center' alignItems='center' h='15px'>
                            <Text w='82%' fontSize='12px' fontWeight={500}color='#E53E3E'>{errors.pwd}</Text>
                        </Flex>:
                        null}
                <Flex justifyContent='center' alignItems='center' my='10px' >
                    <Button w='83%' h='40px' border='none' p='5px' cursor='pointer'
                    outline='none' borderRadius='5px' color='white' backgroundColor='#0F6972'
                    onClick={handleSubmit}>Sign In</Button>
                </Flex>

                <Flex justifyContent='center' mt='15px'>
                    <Link textAlign='center' fontSize='13px' href='/app/passwordRequest'>Forgot password</Link>    
                </Flex>

                
                 
            </Box>
        </Flex>
    )
}



export default Login;