import {
    Box,
    Text,
    Image,
    Link,
    useDisclosure ,
    Flex
    
  } from "@chakra-ui/react"
  import './app.css'
  import { useEffect,useState } from "react";
  import logoImg from '../Images/lg-colored.png'
  import Logo from "../Logo/Logo";
  import { FiYoutube,FiTerminal,FiPlay  ,FiSettings ,FiRss ,FiLock ,FiBarChart2,FiLogOut ,FiDollarSign } from "react-icons/fi";
import {AiOutlineCreditCard ,AiOutlineDashboard } from "react-icons/ai";
import { useLocation } from 'react-router-dom';

const Index=({selected,mainPart,title})=>{

    let webToken
    const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Check if the cookie starts with the specified name
            if (cookie.startsWith('webToken=')) {
                webToken=cookie.substring('webToken='.length)
            }
        }
        if(!webToken){
            const location = new URL(window.location.href)
            const redirect_to=location.pathname+location.search
            let whole_path='/app/login?'+new URLSearchParams({redirect_to})
             window.location.href = whole_path;
        }

    const [showNav,setShowNav]=useState(true)

    const logOut=()=>{
        console.log('Logging out');
        document.cookie = "webToken=;expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        window.location.href = '/app/login';
    }
    
    
    useEffect(()=>{
        

        const hamburger=document.querySelector('#hamburger')
        const navItems=document.querySelector("#navItems")
        // 
        

        if(window.innerWidth>=696){
            setShowNav(true)
        }else{
            setShowNav(false)
        }

        window.addEventListener('resize', ()=>{
            if(window.innerWidth>=696){
                setShowNav(true)
            }
        });
        
    },[])
    

    const changeMenu=(e)=>{
        // e.preventDefault()
        let sec=e.target.id
       
    }
 
    
     
    return(
        <Box id='mainDiv'>

            <Box id='sideNav'>
                <Flex  borderBottom='1px solid #91B496' h='80px' id='logoFlex'>
                        <Logo/>
                        <span style={{fontSize:'25px',color:'white',cursor:"pointer"}} id='hamburger'
                        onClick={()=>{setShowNav(!showNav)}}>&#9776;</span>
                </Flex>

                {showNav?<Box id='navItems'>
                    {/* <Link  textDecoration='none' href='/app/playground' display='flex'  paddingLeft='10px' cursor='pointer' 
                            id='play' onClick={changeMenu} 
                            backgroundColor={selected==='playground'?'#F1F2F5':'#1B5842'} 
                            _hover={{backgroundColor:'#467835'}}
                            alignItems='center' justifyContent='flex-start'   h='35px'>
                                <FiYoutube  color={selected==='playground'?'black':'white'} size='18px'/>
                                <Text fontSize='12px' fontWeight='500' marginLeft='10px' 
                                color={selected==='playground'?'black':'#F1F2F5'} >Playground</Text>
                    </Link> */}


                    <Link textDecoration='none' href='/app/dashboard' display='flex'  paddingLeft='10px' cursor='pointer' 
                        id='keys' onClick={changeMenu}
                        backgroundColor={selected==='dashboard'?'#F1F2F5':'#1B5842'}
                        _hover={{backgroundColor:'#467835'}}
                        alignItems='center' justifyContent='flex-start'   h='35px'>
                            <AiOutlineDashboard  color={selected==='dashboard'?'black':'white'} size='18px'/>
                            <Text fontSize='12px' fontWeight='500' marginLeft='10px'
                            color={selected==='dashboard'?'black':'#F1F2F5'}
                            >Dashboard</Text>
                    </Link>
                    

                    <Link textDecoration='none' href='/app/keys' display='flex'  paddingLeft='10px' cursor='pointer' 
                        id='keys' onClick={changeMenu}
                        backgroundColor={selected==='keys'?'#F1F2F5':'#1B5842'}
                        _hover={{backgroundColor:'#467835'}}
                        alignItems='center' justifyContent='flex-start'   h='35px'>
                            <FiLock  color={selected==='keys'?'black':'white'} size='18px'/>
                            <Text fontSize='12px' fontWeight='500' marginLeft='10px'
                            color={selected==='keys'?'black':'#F1F2F5'}
                            >API Keys</Text>
                    </Link>

                    <Link textDecoration='none' href='/app/usage' display='flex'  paddingLeft='10px' cursor='pointer' 
                        id='keys' onClick={changeMenu}
                        backgroundColor={selected==='usage'?'#F1F2F5':'#1B5842'}
                        _hover={{backgroundColor:'#467835'}}
                        alignItems='center' justifyContent='flex-start'   h='35px'>
                            <FiBarChart2  color={selected==='usage'?'black':'white'} size='18px'/>
                            <Text fontSize='12px' fontWeight='500' marginLeft='10px'
                            color={selected==='usage'?'black':'#F1F2F5'}
                            >Usage</Text>
                    </Link>

                    

                    <Link textDecoration='none' href='/app/credits' display='flex'  paddingLeft='10px' cursor='pointer' 
                        id='keys' onClick={changeMenu}
                        backgroundColor={selected==='credits'?'#F1F2F5':'#1B5842'}
                        _hover={{backgroundColor:'#467835'}}
                        alignItems='center' justifyContent='flex-start'   h='35px'>
                            <AiOutlineCreditCard     color={selected==='credits'?'black':'white'} size='18px'/>
                            <Text fontSize='12px' fontWeight='500' marginLeft='10px'
                            color={selected==='credits'?'black':'#F1F2F5'}
                            >Payments & Billing</Text>
                    </Link>

                    <Link textDecoration='none' href='/app/settings' display='flex' mt='100px'  paddingLeft='10px' cursor='pointer' 
                        id='keys' onClick={changeMenu}
                        backgroundColor={selected==='settings'?'#F1F2F5':'#1B5842'}
                        _hover={{backgroundColor:'#467835'}}
                        alignItems='center' justifyContent='flex-start'   h='35px'>
                            <FiSettings color={selected==='settings'?'black':'white'} size='18px'/>
                            <Text fontSize='12px' fontWeight='500' marginLeft='10px'
                            color={selected==='settings'?'black':'#F1F2F5'}
                            >Settings</Text>
                    </Link>

                    <Text  onClick={logOut} m='0px'
                    display='flex' textDecoration='none' pl='10px' cursor="pointer"
                    _hover={{backgroundColor:'#467835'}} alignItems='center' justifyContent='flex-start'>
                        <FiLogOut color='white' size='20px'/>
                        <Text fontSize='12px' fontWeight='500' marginLeft='10px'
                            color='white'
                            >Log out</Text>
                    </Text>
                </Box>:null}

            </Box>

            {mainPart}

        </Box>
       
    )
}



export default Index;