import {
    Box,Text,Flex,Button,CircularProgress
  } from "@chakra-ui/react"

  import { useEffect, useState } from "react";

  import Navbar from "../components/Navbar/Navbar";
  import FirstSection from "../components/Home/FirstSection";
  import SecondSection from "../components/Home/SecondSection";
  import ThirdSection from "../components/Home/ThirdSection";
  import Footer from "../components/Footer/Footer";
  import Index from '../components/app/Index'
  import Playground from '../components/app/Playground'
  import {CreditBar,UsageBar} from '../components/Graph/graph'

const Usage=()=>{

    let webToken
    const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Check if the cookie starts with the specified name
            if (cookie.startsWith('webToken=')) {
                webToken=cookie.substring('webToken='.length)
            }
        }

    const [defaultDatas,setDefaultDatas]=useState({
        labeLs:['01 Apr', '02 Apr','03 Apr','03 Apr','04 Apr','05 Apr','06 Apr',
        '07 Apr','08 Apr','09 Apr','10 Apr','11 Apr','12 Apr','13 Apr',
        '14 Apr','15 Apr','16 Apr'],
        labeL:'Default Graph',
        daTa:[12, 19, 3, 5, 2, 3,8,4,6]
    })

    const [showLoader,setShowLoader]=useState(false)

    const [allDatas,setAllDatas]=useState({
        sevenDaysData:{labeLs:[],labeL:'',daTa:[]},
        thirtyDaysData:{labeLs:[],labeL:'',daTa:[]},
        ninetyDaysData:{labeLs:[],labeL:'',daTa:[]}
    })
    const [sevenDaysData,setSevenDaysData]=useState({labeLs:[],labeL:'',daTa:[]})
    const [thirtyDaysData,setThirtyDaysData]=useState({labeLs:[],labeL:'',daTa:[]})
    const [ninetyDaysData,setNinetyDaysData]=useState({labeLs:[],labeL:'',daTa:[]})

    const [showGraph,setShowGraph]=useState({show:false,type:'7'})

    const handleOptions=(e)=>{
        setShowGraph({show:false})
        setTimeout(() => {
            // setShowGraph({show:true,type:e.target.value})  
            // console.log(showGraph);
            fetchUsage()
        }, 500)
    }

    const fetchUsage=async(type)=>{
         
         setShowGraph(false)
         setShowLoader(true)
         const typeSelect=document.querySelector('#typeSelect')
        //  let url=`http://localhost:3005/api/credits/all`
        //  let url=`https://api.apedata.net/api/credits/all`
         let url=process.env.REACT_APP_SERVER_HOST+'/api/credits/all'
        fetch(url,{
            method:'GET',
            headers:{"Content-Type":'application/json',"authorization":`Bearer ${webToken}`}
        })
        .then(async res=>{
            const response=await res.json()
            if(res.status===200){
                const {dataPoints}=response
                const {last_7_days,last_30_days,last_90_days}=dataPoints
                
                setShowGraph({show:true,type:typeSelect.value})
                setShowLoader(false)
                setAllDatas(
                    {
                        sevenDaysData:{
                            labeLs:last_7_days.days.reverse(),
                            daTa:last_7_days.data.reverse(),
                            labeL:'API Usage'
                        },
                        thirtyDaysData:{
                            labeLs:last_30_days.days.reverse(),
                            daTa:last_30_days.data.reverse(),
                            labeL:'API Usage'
                            },
                        ninetyDaysData:{
                            labeLs:last_90_days.days.reverse(),
                            daTa:last_90_days.data.reverse(),
                            labeL:'API Usage'
                            }
                    }
                )
               
               
                
            }
        })
    }
    
    useEffect(()=>{
        fetchUsage()
    },[])
    return(
        <Box>
        <Index selected='usage' mainPart={
            <Box>
                <Box h='80px' borderBottom='1px solid grey' display='flex' alignItems='center' px='10px'>
                    <Text fontSize='15px' fontWeight='600'>API USAGE</Text>
                </Box>
                <Flex px='10'>
                    

                    <Box w='100%' mr='10px'>
                        <Text mr='10px ' mt='50px'fontWeight='500' fontSize='14px'>USAGE :</Text>
                        <Box>
                            <select onChange={handleOptions} id='typeSelect'>
                                <option value='7' selected>Last 7 Days</option>
                                <option value='30'>Last 30 Days</option>
                                <option value='90'>Last 90 Days</option>
                                
                            </select>

                            {showLoader?<Box position='absolute' top='50%' right='50%'>
                <CircularProgress isIndeterminate color='#0F6972' />
            </Box>:null}

                            {showGraph.show?
                                showGraph.type==='7'?
                                <UsageBar 
                                    labeLs={allDatas.sevenDaysData.labeLs} 
                                    labeL={allDatas.sevenDaysData.labeL} 
                                    daTa={allDatas.sevenDaysData.daTa}

                                />
                                :showGraph.type==='30'?
                                <UsageBar 
                                    labeLs={allDatas.thirtyDaysData.labeLs} 
                                    labeL={allDatas.thirtyDaysData.labeL} 
                                    daTa={allDatas.thirtyDaysData.daTa}

                                />
                                :showGraph.type==='90'?
                                <UsageBar 
                                    labeLs={allDatas.ninetyDaysData.labeLs} 
                                    labeL={allDatas.ninetyDaysData.labeL} 
                                    daTa={allDatas.ninetyDaysData.daTa}

                                />:null:null}
                        </Box>
                        
                    </Box>
                    {/* <Flex>
                        <Text mr='10px '>Your Plan:</Text>
                        <Text mr='10px ' color='#4988C6'>Subscription 1</Text>
                        <Button backgroundColor='#E47911' color='white'
                        outline='none' border='none'>Upgrade</Button>
                    </Flex> */}
                    
                </Flex>
            </Box>
            }/>
        </Box>
    )
}

export default Usage;