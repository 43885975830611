import {
    Box, Flex,Text,Button, Table,Thead,Tbody,Tr,Th,Td
  } from "@chakra-ui/react"
import { Link } from "react-router-dom"

const DataTypeIntros=()=>{
    return(
        <Box p='10px'>
            <Box px='15px' id='profiles' mb='20px'>
                <Text fontSize='25px' fontWeight='500'>Data Types </Text>
                <Text fontSize='14px'>
                    The "Data Type" endpoint group retrieves the list of items that are valid and applicable
                    for utilization of certain endpoints
                </Text>
            </Box>

            <Box id='people' px='15px'>
                <Text fontSize='20px' fontWeight='500' >Endpoints in this category</Text>
                <Text fontSize='14px'>
                   1. People industries lists
                </Text>
                <Text fontSize='14px'>
                   2. Company industries list
                </Text>
                <Text fontSize='14px'>
                   3. Service categories list
                </Text>
                <Text fontSize='14px'>
                   4. Job titles list
                </Text>
            </Box>

        </Box>
    )
}

// To utilize these 
//                     endpoints, users are required to make an HTTP POST request and provide 
//                     the API key obtained from ApeData. Additionally, users must have a 
//                     positive credit balance in order to access this data.



export default DataTypeIntros