import {Box, Flex,Text,Button, Table,Thead,Tbody,Tr,Th,Td
} from "@chakra-ui/react"
import { Link } from "react-router-dom"

import DocItem from "./DocItem"

const PeopleIndustryDocs=()=>{
    return(
        <Box p='10px'>

            <Box px='15px' id='profiles' mb='20px'>
                <Text fontSize='22px' fontWeight='500'>People Industries List </Text>
                {/* <Text fontSize='14px'>
                    The "Data Type" endpoint group retrieves the list of items that are valid and applicable
                    for utilization of certain endpoints
                </Text> */}
            </Box>
            <Box id='people' px='15px'>
                 <Box>
                    <Text fontSize='14px' fontWeight='500' >HEADERS</Text>
                    <Flex justifyContent='start' mb='50px'>
                        <Box w='90%' backgroundColor='#EBF1F5' minWidth='300px' 
                         pt='10px'>
                            <DocItem name='X-API-KEY' required={true} 
                                explanation='Your api key'
                            />
                        </Box>
                    </Flex>
                </Box>

                <Box>
                    {/* <Text fontSize='14px' fontWeight='500' mb={5}>POST BODY</Text>
                    <Flex justifyContent='start' mb='50px'>
                        <Box w='90%' backgroundColor='#EBF1F5' minWidth='300px' pb='25px' pt='10px'>
                        </Box>
                    </Flex> */}
                </Box>
            </Box>

        </Box>
    )
}


export default PeopleIndustryDocs