import {
    Box, Flex,Text,Button, Table,Thead,Tbody,Tr,Th,Td
  } from "@chakra-ui/react"
import { Link } from "react-router-dom"

import DocItem from "./DocItem"

const ProfileDocs=()=>{
    return(
        <Box p='10px'>
            <Box px='15px' id='profiles' mb='20px'>
                <Text fontSize='22px' fontWeight='500'>Profile Details</Text>
                <span style={{backgroundColor:'#EDEDED',padding:'5px',borderRadius:'5px' }}>
                        <span style={{marginRight:'10px',fontWeight:'500' }}>POST</span>
                        <span>https://api.apedata.net/v1/profile</span>
                </span>
                
            </Box>

            <Box id='people' px='15px'>
                <Box>
                    <Text fontSize='14px' fontWeight='500' >HEADERS</Text>
                    <Flex justifyContent='start' mb='50px'>
                        <Box w='90%' backgroundColor='#EBF1F5' minWidth='300px' 
                         pt='10px'>
                            <DocItem name='X-API-KEY' required={true} 
                                explanation='Your api key'
                            />
                        </Box>
                    </Flex>
                    
                </Box>

                <Box>
                    <Text fontSize='14px' fontWeight='500' mb={5}>POST BODY</Text>
                    <Flex justifyContent='start' mb='50px'>
                        <Box w='90%' backgroundColor='#EBF1F5' minWidth='300px' pb='25px' pt='10px'>
                            <DocItem name='profile_id' required={true} type='string'
                                explanation="The profile's public ID on LinkedIn"
                            />
                            <DocItem name='bypass_cache' type='boolean'
                                explanation='If set to true, our 
                                temporary cache will be ignored, to retrieve fresh data from LinkedIn'
                                Default='false'
                            />
                            <DocItem name='contact_info' type='boolean'
                                explanation='If set to true, the contact information i.e. 
                                email, will be retrieved if available.'
                                Default='false'
                            />
                            <DocItem name='network_info' type='boolean'
                                explanation="If set to true, the information on the profile's connections count 
                                and followers count will be returned."
                                Default='false'
                            />
                            <DocItem name='profile_posts' type='boolean'
                                explanation={`When set to true, the first 10 posts and reposts made by the user will be retrieved.
                                \nTo retrieve more posts, make use of the search posts endpoint `}
                                Default='false'
                            />
                            <DocItem name='badges' type='boolean'
                                explanation='If set to true, the badges ie. premium of the 
                                profile will be returned'
                                Default='false'
                            />
                            <DocItem name='detailed_positions' type='boolean'
                                explanation='If set to true, detailed information on each
                                single position the user has had will be retrived if available'
                                Default='false'
                            />
                            <DocItem name='detailed_skills' type='boolean'
                                explanation='If set to true all available skills of the profile will be retrieved.'
                                Default='false'
                            />
                            {/* <DocItem name='related_profiles' type='boolean'
                                explanation="When set to true, all skills and not just the skills that 
                                are immediately available on the user's profile will be retrieved"
                                Default='false'
                            /> */}
                        </Box>
                    </Flex>
                </Box>
                
            </Box>

        </Box>
    )
}

// To utilize these 
//                     endpoints, users are required to make an HTTP POST request and provide 
//                     the API key obtained from ApeData. Additionally, users must have a 
//                     positive credit balance in order to access this data.



export default ProfileDocs