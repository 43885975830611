import {Box,Flex,Text,AlertDialog,Input,Link,CircularProgress,Button,
    FormControl,
    FormLabel
  } from "@chakra-ui/react"
  import Logo from "../components/Logo/Logo";
  import { useSearchParams  } from 'react-router-dom'

  import { useState } from "react";

  import './pages.css'



  const PasswordReset=()=>{

    function isValidEmail(email) {
        const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    }

    const [emailRes,setEmailRes]=useState({show:false,error:true,message:''})
    const [showLoader,setShowLoader]=useState(false)

    const [errorFds,setErrorFds]=useState({new:false,confirm:false,newText:'',confirmText:''})
    const sleep=(ms)=>{
        return new Promise(async(resolve, reject) => {
            setTimeout(() => {
                resolve('DONE')
            }, ms);
        })
    }
    const [searchParams] = useSearchParams();
    const handleSubmit=async(e)=>{
        e.preventDefault()
        setErrorFds({new:false,confirm:false,newText:'',confirmText:''})
        await sleep(500)
        const newPassword=document.querySelector('#newPwdInput').value
        const confirmPwd=document.querySelector('#confirmPwdInput').value

        if(newPassword.length>3){
            if(newPassword===confirmPwd){
                // let url=`https://api.apedata.net/api/password/reset`
                // let url=`http://localhost:3005/api/password/reset`
                const url= process.env.REACT_APP_SERVER_HOST+'/api/password/reset';
                let token=searchParams.get('token')
                let id=searchParams.get('id')
                setShowLoader(true)
                fetch(url,{
                    method:"POST",
                    headers:{'Content-Type':"application/json"},
                    body:JSON.stringify({newPassword,token,id})

                }).then(async resp=>{
                    setShowLoader(false)
                    let res=await resp.json()
                    if(resp.status===200){
                        setEmailRes({show:true,error:false,message:res.message})
                    }else{
                        setEmailRes({show:true,error:true,message:res.message})
                    }
                    
                })
                .catch(error=>{
                    setShowLoader(false)
                    setErrorFds({new:true,confirm:true,newText:error.message,confirmText:error.message})
                })

            }else{
                setErrorFds({new:true,newText:'Fields must match',confirm:true,confirmText:'Fields must match'})  
            }
            
        }else{
            setErrorFds({...errorFds,new:true,newText:'Please provide a password value'})
        }
        

        return
        

    }
    return(
        <Box  h='100vh' id='password_page'>
            <Box pt='20px' position='relative'>
                <Flex justifyContent='center'>
                    <Logo colored={true}/>
                </Flex>
                <Text color='#0F6972' fontSize='1.5rem' textAlign='center' m={0}>Change your password</Text>
                <Flex justifyContent='center'>
                    <Flex w='40%' minW='300px'>
                        <Text textAlign='center' m={0} fontSize='14px'>
                        Type in your new password. An email confirmation will be sent upon reset
                        </Text>
                    </Flex>
                </Flex>
                {emailRes.show?
                    <Text textAlign='center' m={0}>
                    <Link  m={0} fontSize='15px' href='/app/login'>Login page</Link>
                </Text>:null}
                
                
                

            {emailRes.error?
            <Box>
                <Flex justifyContent='center' mt='20px'>
                    
                    <FormControl  w='79%' >
                        <FormLabel fontSize='13px' fontWeight={500}>New password</FormLabel>
                        <Input type='email' w='90%' h='30px' 
                        border={errorFds.new?'1px solid #F57F80':'1px solid #B4D5D7'}
                        borderRadius='5px' outline='none' required  id='newPwdInput'/>
                    </FormControl>
                </Flex>
                {errorFds.new?<Box px='11%' fontSize='13px'>
                    <Text m={0} color='#F57F80'>{errorFds.newText}</Text>
                </Box>:null}
                

                <Flex justifyContent='center' mt='20px'>
                    
                    <FormControl  w='79%' >
                        <FormLabel fontSize='13px' fontWeight={500}>Confirm password</FormLabel>
                        <Input type='email' w='90%' h='30px' 
                        border={errorFds.confirm?'1px solid #F57F80':'1px solid #B4D5D7'}
                        borderRadius='5px' outline='none' required id='confirmPwdInput'/>

                        {errorFds.confirm?
                        <Box px='1%' fontSize='13px'>
                            <Text m={0} color='#F57F80'>{errorFds.confirmText}</Text>
                        </Box>:null}

                        <Button mt='20px' p='5px' cursor='pointer' backgroundColor='#0F6972' h='36px' px='10px'
                        outline='none' type="submit" border='none' borderRadius='4px' color='white' 
                        onClick={handleSubmit}>Change Password
                        
                        </Button>
                    
                    </FormControl>
                </Flex>
                
                
            </Box>:null}

            {showLoader?<Box position='absolute' top='50%' right='50%'>
                <CircularProgress isIndeterminate color='#0F6972' />
            </Box>:null}



                {emailRes.show?<Flex justifyContent='center' mt='20px'>
                    <Flex alignItems='center' 
                    w='79%' backgroundColor={emailRes.error?'#FAD4D5':'#AFFFE2'} h='80px' borderRadius='10px'
                    border={emailRes.error?'1px solid #F0A2A5':'1px solid #168B62' }
                    px='10px'>
                            
                            {emailRes.error?
                                <span style={{color:'#F57F80',marginRight:'10px',fontSize:'20px',cursor:'pointer'}}
                                onClick={()=>setEmailRes({show:false,error:true,message:''})}>
                            &#10006;
                            </span> :
                            <span style={{color:'#12827C',marginRight:'10px',fontSize:'20px'}}>
                            &#10004;
                            </span> }
                            <Text fontSize='14px'>
                            {emailRes.message}
                            </Text>
                    </Flex>
                </Flex>:null}
            

            </Box>

            <Box backgroundColor='#0F6972'>

            </Box>
            

           
            
        </Box>
    )
  }

  export default PasswordReset;