import {
    Box, Flex,Text,Button, Table,Thead,Tbody,Tr,Th,Td,Link
  } from "@chakra-ui/react"

  import { FiChevronDown,FiChevronRight } from "react-icons/fi";

  import { useLocation, useNavigate } from 'react-router-dom';

import Logo from "../components/Logo/Logo";
import ExamplePanel from "../components/Docs/ExamplePanel";
import { useState,useEffect } from "react";
import '../components/Docs/Docs.css'

import People_Docs from "../components/Docs/people";
import DocsIntro from "../components/Docs/intro";
import ProfilesIntro from "../components/Docs/profilesIntro";
import SearchesIntro from "../components/Docs/searchesIntro";
import DataTypeIntros from "../components/Docs/dataTypeIntro";

import ProfileDocs from "../components/Docs/ProfileDocs";
import CompanyDocs from "../components/Docs/CompanyDocs";

import SearchProfileDocs from "../components/Docs/SearchProfileDocs";
import SearchCompanyDocs from "../components/Docs/SearchCompanyDocs";
import SearchJobsDocs from "../components/Docs/SearchJobsDocs";
import SearchPostsDocs from "../components/Docs/SearchPostsDocs";
import SearchEventsDocs from "../components/Docs/SearchEventsDocs";
import SearchServicesDocs from "../components/Docs/SearchServicesDocs";

import PeopleIndustryDocs from "../components/Docs/PeopleIndustryDocs";
import CompanyIndustryDocs from "../components/Docs/CompanyIndustryDocs";
import ServiceCategoryListDocs from "../components/Docs/ServiceCategoryList";

import UrnSearchesIntro from "../components/Docs/UrnSearchesIntros";
import LocationUrnDocs from "../components/Docs/LocationUrnDocs";
import IndustryUrnDocs from "../components/Docs/IndustryUrnDocs";
import CompanyUrnDocs from "../components/Docs/CompanyUrnDocs";
import ServiceUrnDocs from "../components/Docs/ServiceUrnDocs";




  const Docs=()=>{
    const navigate = useNavigate();
    const [openedField,setOpenField]=useState({
        none:true,search:false,profiles:false,dtypes:false})

    const [showOnPage,setShowOnPage]=useState({intro:true})

    
    const handleListItemClick=(evt,idd)=>{

        const id= evt?evt.currentTarget.id:idd
        
      
        if(id==='profiles'){
            setOpenField({...openedField,profiles:!(openedField.profiles),dtypes:false,search:false})
            setShowOnPage({profiles:true})
            navigate('#profiles')
        }
        else if(id==='searches'){
            setOpenField({...openedField,search:!(openedField.search),dtypes:false,profiles:false})
            setShowOnPage({searches:true})
            navigate('#searches')
        }
        else if(id==='dtypes'){
            setOpenField({...openedField,dtypes:!(openedField.dtypes),search:false,profiles:false})
            navigate('#dtypes')
            setShowOnPage({dtypes:true})
        }

        else if(id==='show_people_prof'){
            setOpenField({profiles:true})
            navigate('#show_people_prof')
            setShowOnPage({people_profiles:true})
        }
        else if(id==='show_company_prof'){
            setOpenField({profiles:true})
            navigate('#show_company_prof')
            setShowOnPage({company_profiles:true})
            
        }
        else if(id==='show_people_search'){
            setOpenField({search:true})
            navigate('#show_people_search')
            setShowOnPage({people_search:true})
            
        }
        else if(id==='show_company_search'){
            setOpenField({search:true})
            navigate('#show_company_search')
            setShowOnPage({company_search:true})
        }
        else if(id==='show_posts_search'){
            setOpenField({search:true})
            navigate('#show_posts_search')
            setShowOnPage({posts_search:true})
        }
        else if(id==='show_jobs_search'){
            setOpenField({search:true})
            navigate('#show_jobs_search')
            setShowOnPage({jobs_search:true})
        }
        else if(id==='show_events_search'){
            setOpenField({search:true})
            navigate('#show_events_search')
            setShowOnPage({events_search:true})
        }
        else if(id==='show_services_search'){
            setOpenField({search:true})
            navigate('#show_services_search')
            setShowOnPage({services_search:true})
        }
        
        else if(id==='urn_searches'){
            setOpenField({urn_searches:true})
            navigate('#urn_searches')
            setShowOnPage({urn_intro:true})
        }
        else if(id==='show_location_urn'){
            setOpenField({urn_searches:true})
            navigate('#show_location_urn')
            setShowOnPage({location_urn:true})
        }
        else if(id==='show_industry_urn'){
            setOpenField({urn_searches:true})
            navigate('#show_industry_urn')
            setShowOnPage({industry_urn:true})
        }
        else if(id==='show_company_urn'){
            setOpenField({urn_searches:true})
            navigate('#show_company_urn')
            setShowOnPage({company_urn:true})
        }
        else if(id==='show_service_urn'){
            setOpenField({urn_searches:true})
            navigate('#show_service_urn')
            setShowOnPage({service_urn:true})
        }
        else if(id==='intro'){
            setShowOnPage({intro:true})
            setOpenField({none:true})
            navigate('#')
            return
        }

        let allListItems=document.querySelectorAll('.listItem')

        allListItems.forEach(elmt=>{
            if(idd && elmt.id===idd){
            
                elmt.style.backgroundColor='#D3D3D3'
            }else{
                elmt.style.backgroundColor='inherit'
            }
            
        })
        

        if(evt){
            evt.currentTarget.style.backgroundColor='#D3D3D3'
        }
        
        
    }
    const toggleBasicField=(e)=>{
        console.log(e.currentTarget);
        setOpenField({...openedField,basic:!openedField.basic,selected:'AA'})

    }
    const toggleSearchField=()=>{
        setOpenField({...openedField,search:!openedField.search,selected:'BB'})
    }

    
    // console.log(theHash);
    

    const setSelected=(e)=>{
        let val=e.target.id
        console.log(val);
        const allSelectors=document.querySelectorAll('.selectors')
        allSelectors.forEach(elmt=>{
            elmt.style.backgroundColor='inherit'
        })
        e.target.style.backgroundColor='#EDEDED'
        setOpenField({...openedField,selected:val})
        if(val.includes('A')){

        }else{

        }
    }
   

    useEffect(()=>{
        const theURL=new URL(window.location.href)
        const theHash=theURL.hash.substring(1)
        console.log(theHash);
        handleListItemClick(null,theHash)
        // if(theHash && theHash[1]){
        //     handleListItemClick(null,theHash)
        // }
        
        
        
    },[])
    return(
        <Box h='100vh'>
           
            <Flex justifyContent='start' alignItems='center' backgroundColor='black'
            h='60px' px='15px'>

                <Logo/>
            </Flex>

            <Box id='mainBox' minH='100vh'>
                <Box id='endPointsListDiv' backgroundColor='#F5F6F8' h='100vh'>
                        <Text fontSize='18px' fontWeight='500' ml='10px' id='intro' 
                        cursor='pointer'onClick={handleListItemClick}>
                            {/* <Link href='#' color='black'>API Endpoints</Link> */}
                            API Endpoints
                        </Text>

                        <Box>

                            <Flex alignItems='center' cursor='pointer' justifyContent='space-between'
                            onClick={handleListItemClick} px='7px' id='profiles'
                            className='listItem'>
                                <Text fontSize='14px'>People & Company Data </Text>
                                <Box position='relative' top='4px' >
                                {openedField.profiles?<FiChevronDown color='black' size='20px'/>:
                                <FiChevronRight color='black' size='20px'/>}
                                </Box>
                                
                            </Flex>

                            {openedField.profiles?<Box>
                                    <Flex alignItems='center' h='30px' my='3px' p='2px'
                                     cursor='pointer'
                                    onClick={handleListItemClick} id='show_people_prof'
                                    className='listItem'>
                                        <Button border='1px solid #186FAF' 
                                        outline='none' fontSize='10px' h='15px'
                                        backgroundColor='#186FAF' color='white' mx='5px'
                                        borderRadius='1px'>POST</Button>
                                        <Text fontSize='13px'>Profile details</Text>
                                    </Flex>

                                    <Flex alignItems='center'  h='30px' my='3px' p='2px'
                                        cursor='pointer' id='show_company_prof' onClick={handleListItemClick}
                                        className='listItem'>
                                        <Button border='1px solid #186FAF' 
                                        outline='none' fontSize='10px' h='15px'
                                        backgroundColor='#186FAF' color='white' mx='5px'
                                        borderRadius='1px'>POST</Button>
                                        <Text fontSize='13px'>Company details</Text>
                                    </Flex>
                            </Box>:null}

                        </Box>
                        
                        <Box>

                            <Flex alignItems='center' cursor='pointer' justifyContent='space-between'
                            onClick={handleListItemClick} px='7px'
                            className='listItem' id='searches'>
                                <Text fontSize='14px'>LinkedIn Search </Text>
                                <Box position='relative' top='4px' >
                                {openedField.search?<FiChevronDown color='black' size='20px'/>:
                                <FiChevronRight color='black' size='20px'/>}
                                </Box>
                                
                            </Flex>

                            {openedField.search?<Box>
                                    <Flex alignItems='center' h='30px' my='3px' p='2px'
                                     cursor='pointer'
                                    onClick={handleListItemClick} id='show_people_search'
                                    className='listItem'>
                                        <Button border='1px solid #186FAF' 
                                        outline='none' fontSize='10px' h='15px'
                                        backgroundColor='#186FAF' color='white' mx='5px'
                                        borderRadius='1px'>POST</Button>
                                        <Text fontSize='13px'>People Search</Text>
                                    </Flex>

                                    <Flex alignItems='center'  h='30px' my='3px' p='2px'
                                        cursor='pointer' id='show_company_search' onClick={handleListItemClick}
                                        className='listItem'>
                                        <Button border='1px solid #186FAF' 
                                        outline='none' fontSize='10px' h='15px'
                                        backgroundColor='#186FAF' color='white' mx='5px'
                                        borderRadius='1px'>POST</Button>
                                        <Text fontSize='13px'>Company Search</Text>
                                    </Flex>

                                    <Flex alignItems='center'  h='30px' my='3px' p='2px'
                                        cursor='pointer' id='show_posts_search' onClick={handleListItemClick}
                                        className='listItem'>
                                        <Button border='1px solid #186FAF' 
                                        outline='none' fontSize='10px' h='15px'
                                        backgroundColor='#186FAF' color='white' mx='5px'
                                        borderRadius='1px'>POST</Button>
                                        <Text fontSize='13px'>LinkedIn Posts Search</Text>
                                    </Flex>

                                    <Flex alignItems='center'  h='30px' my='3px' p='2px'
                                        cursor='pointer' id='show_jobs_search' onClick={handleListItemClick}
                                        className='listItem'>
                                        <Button border='1px solid #186FAF' 
                                        outline='none' fontSize='10px' h='15px'
                                        backgroundColor='#186FAF' color='white' mx='5px'
                                        borderRadius='1px'>POST</Button>
                                        <Text fontSize='13px'>Jobs Search</Text>
                                    </Flex>

                                    <Flex alignItems='center'  h='30px' my='3px' p='2px'
                                        cursor='pointer' id='show_events_search' onClick={handleListItemClick}
                                        className='listItem'>
                                        <Button border='1px solid #186FAF' 
                                        outline='none' fontSize='10px' h='15px'
                                        backgroundColor='#186FAF' color='white' mx='5px'
                                        borderRadius='1px'>POST</Button>
                                        <Text fontSize='13px'>Events Search</Text>
                                    </Flex>

                                    <Flex alignItems='center'  h='30px' my='3px' p='2px'
                                        cursor='pointer' id='show_services_search' onClick={handleListItemClick}
                                        className='listItem'>
                                        <Button border='1px solid #186FAF' 
                                        outline='none' fontSize='10px' h='15px'
                                        backgroundColor='#186FAF' color='white' mx='5px'
                                        borderRadius='1px'>POST</Button>
                                        <Text fontSize='13px'>Services Search</Text>
                                    </Flex>
                            </Box>:null}

                        </Box>

                        <Box>
                            <Flex alignItems='center' cursor='pointer' justifyContent='space-between'
                            onClick={handleListItemClick} px='7px'
                            className='listItem' id='urn_searches'>
                                <Text fontSize='14px'>Search Urns </Text>
                                <Box position='relative' top='4px' >
                                {openedField.urn_searches?<FiChevronDown color='black' size='20px'/>:
                                <FiChevronRight color='black' size='20px'/>}
                                </Box>
                                
                            </Flex>

                            {openedField.urn_searches?<Box>
                                    <Flex alignItems='center' h='30px' my='3px' p='2px'
                                     cursor='pointer'
                                    onClick={handleListItemClick} id='show_location_urn'
                                    className='listItem'>
                                        <Button border='1px solid #186FAF' 
                                        outline='none' fontSize='10px' h='15px'
                                        backgroundColor='#186FAF' color='white' mx='5px'
                                        borderRadius='1px'>POST</Button>
                                        <Text fontSize='13px'>Location Urn Search</Text>
                                    </Flex>

                                    <Flex alignItems='center' h='30px' my='3px' p='2px'
                                     cursor='pointer'
                                    onClick={handleListItemClick} id='show_industry_urn'
                                    className='listItem'>
                                        <Button border='1px solid #186FAF' 
                                        outline='none' fontSize='10px' h='15px'
                                        backgroundColor='#186FAF' color='white' mx='5px'
                                        borderRadius='1px'>POST</Button>
                                        <Text fontSize='13px'>Industry Urn Search</Text>
                                    </Flex>

                                    <Flex alignItems='center' h='30px' my='3px' p='2px'
                                     cursor='pointer'
                                    onClick={handleListItemClick} id='show_company_urn'
                                    className='listItem'>
                                        <Button border='1px solid #186FAF' 
                                        outline='none' fontSize='10px' h='15px'
                                        backgroundColor='#186FAF' color='white' mx='5px'
                                        borderRadius='1px'>POST</Button>
                                        <Text fontSize='13px'>Company Urn Search</Text>
                                    </Flex>
                                    

                                    <Flex alignItems='center'  h='30px' my='3px' p='2px'
                                        cursor='pointer' id='show_service_urn' onClick={handleListItemClick}
                                        className='listItem'>
                                        <Button border='1px solid #186FAF' 
                                        outline='none' fontSize='10px' h='15px'
                                        backgroundColor='#186FAF' color='white' mx='5px'
                                        borderRadius='1px'>POST</Button>
                                        <Text fontSize='13px'>Service Urn Search</Text>
                                    </Flex>
                            </Box>:null}
                        </Box>

                        {/* <Box>

                            <Flex alignItems='center' cursor='pointer' justifyContent='space-between'
                            onClick={handleListItemClick} px='7px'
                            className='listItem' id='dtypes'>
                                <Text fontSize='14px'>Data Types </Text>
                                <Box position='relative' top='4px' >
                                {openedField.dtypes?<FiChevronDown color='black' size='20px'/>:
                                <FiChevronRight color='black' size='20px'/>}
                                </Box>
                                
                            </Flex>

                            {openedField.dtypes?<Box>
                                    <Flex alignItems='center' h='30px' my='3px' p='2px'
                                     cursor='pointer'
                                    onClick={handleListItemClick} id='show_people_industry'
                                    className='listItem'>
                                        <Button border='1px solid #2F8132' 
                                        outline='none' fontSize='10px' h='15px'
                                        backgroundColor='#2F8132' color='white' mx='5px'
                                        borderRadius='1px'>GET</Button>
                                        <Text fontSize='13px'>People Industries List</Text>
                                    </Flex>

                                    <Flex alignItems='center' h='30px' my='3px' p='2px'
                                     cursor='pointer'
                                    onClick={handleListItemClick} id='show_company_industry'
                                    className='listItem'>
                                        <Button border='1px solid #2F8132' 
                                        outline='none' fontSize='10px' h='15px'
                                        backgroundColor='#2F8132' color='white' mx='5px'
                                        borderRadius='1px'>GET</Button>
                                        <Text fontSize='13px'>Company Industries List</Text>
                                    </Flex>

                                    <Flex alignItems='center'  h='30px' my='3px' p='2px'
                                        cursor='pointer' id='show_service_categories' onClick={handleListItemClick}
                                        className='listItem'>
                                        <Button border='1px solid #2F8132' 
                                        outline='none' fontSize='10px' h='15px'
                                        backgroundColor='#2F8132' color='white' mx='5px'
                                        borderRadius='1px'>GET</Button>
                                        <Text fontSize='13px'>Service Categories</Text>
                                    </Flex>
                            </Box>:null}

                            

                        </Box> */}
                </Box>
                <Box id='endpointExplanationDiv'
                h='90vh' overflow='auto' pb='10vh'>
                    {

                    showOnPage.intro?<DocsIntro/>:
                    showOnPage.profiles?<ProfilesIntro/>:
                    showOnPage.searches?<SearchesIntro/>:
                    showOnPage.dtypes?<DataTypeIntros/>:
                    showOnPage.people_profiles?<ProfileDocs/>:
                    showOnPage.company_profiles?<CompanyDocs/>:
                    showOnPage.people_search?<SearchProfileDocs/>:
                    showOnPage.company_search?<SearchCompanyDocs/>:
                    showOnPage.posts_search?<SearchPostsDocs/>:
                    showOnPage.jobs_search?<SearchJobsDocs/>:
                    showOnPage.events_search?<SearchEventsDocs/>:
                    showOnPage.services_search?<SearchServicesDocs/>:
                    showOnPage.people_industry?<PeopleIndustryDocs/>:
                    showOnPage.company_industry?<CompanyIndustryDocs/>:
                    showOnPage.service_categories?<ServiceCategoryListDocs/>:
                    showOnPage.urn_intro?<UrnSearchesIntro/>:
                    showOnPage.location_urn?<LocationUrnDocs/>:
                    showOnPage.industry_urn?<IndustryUrnDocs/>:
                    showOnPage.company_urn?<CompanyUrnDocs/>:
                    showOnPage.service_urn?<ServiceUrnDocs/>:
                    <DocsIntro/>
                    }
                    
                </Box>
                <Box id='endpointExampleDiv' backgroundColor='#2E3336' h='100vh'>
                    
                </Box>
            </Box>
       
            {false?<Box display='grid' gridTemplateColumns='20% 80%' h='85vh' id='mainBox'>
                <Box  p='15px' backgroundColor='#F5F6F8' minW='200px'>
                    

                    <Box>
                        
                        {openedField.basic && false?
                            <Box px='5px'>
                            <Flex alignItems='center' h='30px' my='3px' p='2px'
                             cursor='pointer' className='selectors' id='A0' onClick={setSelected}>
                                <Button border='1px solid #186FAF' 
                                outline='none' fontSize='10px' h='15px'
                                backgroundColor='#186FAF' color='white' mx='2px'
                                borderRadius='1px'>POST</Button>
                                <Text fontSize='13px'>Profile</Text>
                            </Flex>
                        
                            <Flex alignItems='center'  h='30px' my='3px' p='2px'
                            cursor='pointer' className='selectors' id='A1' onClick={setSelected}>
                                <Button border='1px solid #186FAF' 
                                outline='none' fontSize='10px' h='15px'
                                backgroundColor='#186FAF' color='white' mx='2px'
                                borderRadius='1px'>POST</Button>
                                <Text fontSize='13px'>Company</Text>
                            </Flex>
                        </Box>:null}
                        
                        

                    </Box>

                    <Box>
                    {/* <Flex alignItems='center' cursor='pointer' justifyContent='space-between' 
                    onClick={toggleSearchField} >
                            <Text fontSize='16px'>Search People & Companies </Text>
                            <Box position='relative' top='4px' >
                            {openedField.search?<FiChevronDown color='black' />:
                            <FiChevronRight color='black' />} </Box>
                    </Flex> */}
                    {openedField.search?
                        <Box px='5px'>
                            <Flex alignItems='center' h='30px' my='3px' p='2px' cursor='pointer'
                            className='selectors' id='B0' onClick={setSelected}>
                                <Button border='1px solid #186FAF' 
                                outline='none' fontSize='10px' h='15px'
                                backgroundColor='#186FAF' color='white' mx='2px'
                                borderRadius='1px'>POST</Button>
                                <Text fontSize='13px'>Profiles</Text>
                            </Flex>
                        
                            <Flex alignItems='center'  h='30px' my='3px' p='2px'
                            cursor='pointer'
                            className='selectors' id='B1' onClick={setSelected}>
                                <Button border='1px solid #186FAF' 
                                outline='none' fontSize='10px' h='15px'
                                backgroundColor='#186FAF' color='white' mx='2px'
                                borderRadius='1px'>POST</Button>
                                <Text fontSize='13px'>Companies</Text>
                            </Flex>
                        </Box>:null}
                        

                    </Box>

                    
                    
                </Box>
                <Box  p='15px' overflowY='auto' h='400px'>
                        {openedField.selected==='A0'?
                        <Box px='15px'>
                            <Text fontSize='20px' fontWeight='500' borderBottom='1px solid #1D2429' 
                            my='10px'>Profile Data </Text>
                            
                            <span style={{backgroundColor:'#EDEDED',padding:'5px',borderRadius:'5px' }}>
                                <span style={{marginRight:'10px',fontWeight:'500' }}>POST</span>
                                <span>https://api.apedata.net/profile</span>
                            </span>

                            {/* <ExamplePanel url='https://api.apedata.net/profile'/> */}
                            <Text fontSize='16px' fontWeight='500' color='#8e9194' 
                            borderBottom='1px solid #bbbdbe' my='20px'>Request Parameters </Text>

                            <table >
                                <thead>
                                    <tr >
                                        <th>Parameter</th>
                                        <th>Details</th>
                                        <th>Example</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>profile_id</td>
                                        <td>string</td>
                                        <td>'williamhgates'</td>
                                    </tr>
                                    <tr>
                                        <td>bypass_cache</td>
                                        <td>boolean</td>
                                        <td>false</td>
                                    </tr>
                                </tbody>
                            </table>
                            

                        </Box>:


                        openedField.selected==='A1'?
                        <Box px='15px'>
                            <Text fontSize='20px' fontWeight='500' borderBottom='1px solid #1D2429' 
                            my='10px'>Company Data </Text>
                            
                            <span style={{backgroundColor:'#EDEDED',padding:'5px',borderRadius:'5px' }}>
                                <span style={{marginRight:'10px',fontWeight:'500' }}>POST</span>
                                <span>https://api.apedata.net/company</span>
                            </span>

                            <Text fontSize='16px' fontWeight='500' color='#8e9194' 
                            borderBottom='1px solid #bbbdbe' my='20px'>Request Parameters </Text>

                            <table >
                                <thead>
                                    <tr >
                                        <th>Parameter</th>
                                        <th>Details</th>
                                        <th>Example</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>company_id</td>
                                        <td>string</td>
                                        <td>'ibm'</td>
                                    </tr>
                                    <tr>
                                        <td>bypass_cache</td>
                                        <td>boolean</td>
                                        <td>false</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Box>:


                        openedField.selected==='B0'?
                        <Box px='15px'>
                            <Text fontSize='20px' fontWeight='500' borderBottom='1px solid #1D2429' 
                            my='10px'>Search Profiles </Text>
                            
                            <span style={{backgroundColor:'#EDEDED',padding:'5px',borderRadius:'5px' }}>
                                <span style={{marginRight:'10px',fontWeight:'500' }}>POST</span>
                                <span>https://api.apedata.net/profileSearch</span>
                            </span>

                            <Text fontSize='16px' fontWeight='500' color='#8e9194' 
                            borderBottom='1px solid #bbbdbe' my='20px'>Request Parameters </Text>

                            <table >
                                <thead>
                                    <tr >
                                        <th>Parameter</th>
                                        <th>Details</th>
                                        <th>Example</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>profile_id</td>
                                        <td>string</td>
                                        <td>'williamhgates'</td>
                                    </tr>
                                    <tr>
                                        <td>bypass_cache</td>
                                        <td>boolean</td>
                                        <td>false</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Box>:


                        openedField.selected==='B1'?
                        <Box px='15px'>
                            <Text fontSize='20px' fontWeight='500' borderBottom='1px solid #1D2429' 
                            my='10px'>Search Companies </Text>
                            
                            <span style={{backgroundColor:'#EDEDED',padding:'5px',borderRadius:'5px' }}>
                                <span style={{marginRight:'10px',fontWeight:'500' }}>POST</span>
                                <span>https://api.apedata.net/companySearch</span>
                            </span>
                        </Box>:


                        openedField.selected==='AA'?
                        <Box px='15px'>
                            <Text fontSize='25px' fontWeight='500'>People and Company Data </Text>
                            <Text fontSize='14px'>
                                The "People & Company Data" endpoint group provides access to real-time 
                                LinkedIn data for both individuals and companies. To utilize these 
                                endpoints, users are required to make an HTTP POST request and provide 
                                the API key obtained from ApeData. Additionally, users must have a 
                                positive credit balance in order to access this data.
                            </Text>
                        </Box>:


                        openedField.selected==='BB'?
                        <Box px='15px'>
                            <Text fontSize='25px' fontWeight='500'>Search People and Companies </Text>
                            <Text fontSize='14px'>
                                The "People and Companies serach" endpoint facilitates targeted searches for 
                                up-to-date personal and company profiles. To utilize these 
                                endpoints, users are required to make an HTTP POST request and provide 
                                the API key obtained from ApeData. Additionally, users must have a 
                                positive credit balance in order to access this data.
                            </Text>
                        </Box>:null
                         }
                        
                       

                </Box>
                
            </Box>:null}
            
            
        </Box>
    )
}

export default Docs;