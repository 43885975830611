import './App.css';
import Home from './pages/home';
import Docs from './pages/docs';
import Index from './pages/index';
import Usage from './pages/usage';
import Keys from './pages/keys';
import Settings from './pages/settings';
import Playground from './pages/playground';
import PasswordRequest from './pages/passwordRequest';
import PasswordReset from './pages/passwordReset';
import Pricing from './pages/pricing';
import Credits from './pages/credits';
import Purchase from './pages/purchase';
import Dashboard from './pages/dashboard';
import Privacy from './pages/privacy';
import Tos from './pages/tos';
import Contact from './pages/contact';

// import Index from './components/app/Index';
import Register from './components/app/Register';
import Login from './components/app/Login';
import { Routes, Route ,Router } from 'react-router-dom';
function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />}/>
      <Route exact path="/docs" element={<Docs />}/>
      <Route path='/pricing' element={<Pricing />} />
      <Route path='/privacy' element={<Privacy />} />
      <Route path='/terms-of-service' element={<Tos />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='app' element={<Index />}/>
          <Route path='app/login' element={<Login />} /> {/*A nested route!*/}
          <Route path='app/register' element={<Register />} /> {/*A nested route!*/}
          <Route path='app/keys' element={<Keys />} /> {/*A nested route!*/}
          <Route path='app/usage' element={<Usage />} /> {/*A nested route!*/}
          <Route path='app/settings' element={<Settings />} /> {/*A nested route!*/}
          <Route path='app/playground' element={<Playground />} />
          <Route path='app/passwordRequest' element={<PasswordRequest />} />
          <Route path='app/PasswordReset' element={<PasswordReset />} />
          <Route path='app/credits' element={<Credits />} />
          <Route path='app/purchase' element={<Purchase />} />
          <Route path='app/dashboard' element={<Dashboard />} />
     
    </Routes>
  );
}

export default App;
