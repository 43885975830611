import {
    Box, Flex,Text,Button, Table,Thead,Tbody,Tr,Th,Td
  } from "@chakra-ui/react"
import { Link } from "react-router-dom"

import DocItem from "./DocItem"

const SearchProfileDocs=()=>{
    return(
        <Box p='10px'>
            <Box px='15px' id='profiles' mb='20px'>
                <Text fontSize='22px' fontWeight='500'>Search LinkedIn Profiles</Text>
                <span style={{backgroundColor:'#EDEDED',padding:'5px',borderRadius:'5px' }}>
                        <span style={{marginRight:'10px',fontWeight:'500' }}>POST</span>
                        <span>https://api.apedata.net/v1/search/people</span>
                </span>
            </Box>

            <Box id='people' px='15px'>
                <Box>
                    <Text fontSize='14px' fontWeight='500' >HEADERS</Text>
                    <Flex justifyContent='start' mb='50px'>
                        <Box w='90%' backgroundColor='#EBF1F5' minWidth='300px' 
                         pt='10px'>
                            <DocItem name='X-API-KEY' required={true} 
                                explanation='Your api key'
                            />
                        </Box>
                    </Flex>
                </Box>

                <Box>
                    <Text fontSize='14px' fontWeight='500' mb={5}>POST BODY</Text>
                    <Flex justifyContent='start' mb='50px'>
                        <Box w='90%' backgroundColor='#EBF1F5' minWidth='300px' pb='25px' pt='10px'>
                            <DocItem name='total_results' type='number' Default={10}
                                explanation='Maximum length of results to be retrieved'
                            />
                            <DocItem name='start' type='number' Default={'0'}
                                explanation='Index to start searching from. If total_results is set to 
                                10, you will have to set start value to 11 to get the second page.'
                            />
                            <DocItem name='keywords' type='string'
                                explanation={`Keywords to base search on.
                                \nThe keywords will be used to check for a match with the user's name, summary, headline, location, title e.t.c`}
                            />
                            <DocItem name='keyword_first_name' type='string'
                                explanation="The keyword that the users' first name should match"
                            />
                            <DocItem name='keyword_last_name' type='string'
                                explanation="The keyword that the users' last name should match"
                            />
                            <DocItem name='keyword_job_title' type='string'
                                explanation="The keywords with which to match the users's current job title "
                                example='"software engineer"'
                            />
                            <DocItem name='locations' type='array of strings'
                                explanation={`A list of the location urnIds of where the profiles are from. The location urnId can be of a country, city of region.
                                \nTh get a particular location's urnId. Make use of the search locations endpoint in the search urns category `}
                                example='["100446943","90009871"]'
                            />
                            <DocItem name='keyword_company' type='string'
                                example='"google"'
                                explanation="The keywords with which to match for the user's current company"                                Default='false'
                            />
                            <DocItem name='current_companies' type='array of strings'
                                explanation={`A list of the unique urnIds of the companies where the users currently work.
                                \nNB:Using a company's urnId makes for a better and more exact matches.
                                \nTo get a particular company's urnId, you can make use of the company urn endpoint in the searh urns category`}
                                example='["1009","1441]'
                            />
                             <DocItem name='past_companies' type='array of strings'
                             explanation={`A list of the unique urnIds of the companies where the users have worked in the past.
                                \nTo get a particular company's urnId, you can make use of the company urn endpoint in the searh urns category`}
                                example='["1009","1441"]'
                            />
                            <DocItem name='industries' type='array of strings'
                                explanation={`A list of industry urn Ids where the users are working in.
                                \nYou can get a valid industry urn using the industry urn endpoint in the search urns category`}
                            />
                            {/* You can retrieve the list of valid industry names in the get industries endpoint */}
                            
                            {/* <DocItem name='schools' type='array of strings'
                                explanation={`A list of urnIds of the schools where the users attended.
                                \nTo get a particular school's urnId, you can search for the school name in the search schools endpoint`}
                                example='["1009","1441"]'
                            /> */}
                            {/* <DocItem name='keyword_school' type='string'
                                explanation="The keywords with which to match for the school the users went"
                                example='"Harvard"'
                            /> */}
                            <DocItem name='languages' type='array of strings'
                                explanation='List of languages spoken by the searched users'
                                example='["English","French"]'
                            />
                            
                        </Box>
                    </Flex>
                </Box>
                
            </Box>

        </Box>
    )
}

// To utilize these 
//                     endpoints, users are required to make an HTTP POST request and provide 
//                     the API key obtained from ApeData. Additionally, users must have a 
//                     positive credit balance in order to access this data.



export default SearchProfileDocs