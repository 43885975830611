import {
    Box, Flex,Text,Button, Table,Thead,Tbody,Tr,Th,Td
  } from "@chakra-ui/react"
import { Link } from "react-router-dom"

import DocItem from "./DocItem"

const SearchCompanyDocs=()=>{
    return(
        <Box p='10px'>
            <Box px='15px' id='profiles' mb='20px'>
                <Text fontSize='22px' fontWeight='500'>Search Comapnies on LinkedIn </Text>
                <span style={{backgroundColor:'#EDEDED',padding:'5px',borderRadius:'5px' }}>
                        <span style={{marginRight:'10px',fontWeight:'500' }}>POST</span>
                        <span>https://api.apedata.net/v1/search/companies</span>
                </span>
            </Box>

            <Box id='people' px='15px'>
                <Box>
                    <Text fontSize='14px' fontWeight='500' >HEADERS</Text>
                    <Flex justifyContent='start' mb='50px'>
                        <Box w='90%' backgroundColor='#EBF1F5' minWidth='300px' 
                         pt='10px'>
                            <DocItem name='X-API-KEY' required={true} 
                                explanation='Your api key'
                            />
                        </Box>
                    </Flex>
                </Box>

                <Box>
                    <Text fontSize='14px' fontWeight='500' mb={5}>POST BODY</Text>
                    <Flex justifyContent='start' mb='50px'>
                        <Box w='90%' backgroundColor='#EBF1F5' minWidth='300px' pb='25px' pt='10px'>
                            <DocItem name='total_results' type='number' Default={10}
                                explanation='Maximum length of results to be retrieved'
                            />
                            <DocItem name='start' type='number' Default={'0'}
                                explanation='Index to start searching from. If total_results is set to 
                                10, you will have to set start value to 11 to get the second page.' 
                            />
                            <DocItem name='keywords' type='string'
                                explanation="Keywords to base search on. The keywords will check for a match with the company's name"
                            />
                            <DocItem name='industries' type='array of strings'
                                explanation={`A list of urn Ids of the industries to filter the companies by.
                                \nUse the industries urns endpoint in the search urns category to get valid industries and their corresponding urn Ids`}
                            />
                            
                            <DocItem name='countries' type='array of strings'
                                explanation=
                                {`A list of urn Ids of the country where the filtered companies should be based.
                                \nGet a country's urn id from the location endpoint in the search Urns category`}
                                example='["103644278","101165590"]'
                            />
                            <DocItem name='company_size' 
                            type={`limited strings : \none of "1-10", "11-50", "51-200", "201-500", "501-1000", "1001-5000", "5001-10000", "10001+" only`}
                                explanation={`Refine the results of the companies based on their range of number of staff`}
                                example='"51-200"'
                            />
                             <DocItem name='hiring_on_linkedin' type='boolean'
                                explanation={`Whether the company has open job listings on LinkedIn`}
                                example='true'
                            />
                            
                            
                        </Box>
                    </Flex>
                </Box>
                
            </Box>

        </Box>
    )
}

// To utilize these 
//                     endpoints, users are required to make an HTTP POST request and provide 
//                     the API key obtained from ApeData. Additionally, users must have a 
//                     positive credit balance in order to access this data.



export default SearchCompanyDocs