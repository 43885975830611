
import {
    Box, Flex,Text,Button, Table,Thead,Tbody,Tr,Th,Td
  } from "@chakra-ui/react"
import { Link } from "react-router-dom"



const DocItem=({name,required,optional,explanation,type,Default,example})=>{
    return(
        <Flex mb='10px' borderBottom='1px solid grey'>
            <Box w='30%' textAlign='end' px='10px'>
                <Text fontWeight='500' m='5px' fontSize='14px'>{name}</Text>
                {required?<Button fontWeight='500' m='3px' fontSize='12px'
                border='none' outline='none' color='#F62300'>REQUIRED</Button>:null}
                
            </Box>
            <Box w='70%' px='10px' style={{"whiteSpace": "pre-wrap"}}>
                <Text m='5px' fontSize='14px' mx='0px'>{type}</Text>
                {Default?
                <Text m='5px' fontSize='14px' mx='0px' color='#298A93'>
                    Default:
                    <Button border='none' outline='none' backgroundColor='#A4A8AB' 
                    mx='3px' borderRadius='4px'>
                    {Default}
                    </Button>
                </Text>:null}
                {example?
                <Text m='5px' fontSize='14px' mx='0px' color='#298732'>
                    Example: 
                    {` ${example}`}
                </Text>:null}
                <Text mt='5px' fontSize='14px'>
                    {explanation}
                    
                </Text>
            </Box>
        </Flex>
    )
}


export default DocItem
