import {
    Box,Text ,Flex,Button,Link
  } from "@chakra-ui/react"


  import Navbar from "../components/Navbar/Navbar";
  import Footer from "../components/Footer/Footer";
  import { useEffect,useState } from "react";

  import './pages.css'



const Pricing=()=>{
    const [pckg,setPckg]=useState('monthly')

    const [discount,setDiscount]=useState(0)
    const [prices,setPrices]=useState({
        free:0,
        basic:40,
        plus:100,
        pro:350,
        business:1200
    })

    const changePckg=()=>{
        if(pckg==='monthly'){
            setPckg('yearly')
            setDiscount(20)
        }else{
            setPckg('monthly')
            setDiscount(0)
        }
        
    } 
    const makeRequest=(plan)=>{
        let params=new URLSearchParams({package:pckg,plan})
        let url='/app/purchase?'+params
        return(url)
    }
    const getToUrl=(e)=>{
        e.preventDefault()
        console.log(e.target);
    }
    return (
        <Box backgroundColor='#E6F8F7'>
            <Navbar light={true}/>
            <Box px='10px' py='10px'>
                <Text textAlign='center' fontSize='3.2rem' fontWeight='600'
                color='#F5A08C' m={0}>
                    Subscription Plans
                </Text>
                
                <Text textAlign='center' m={0} color='#747474' fontWeight='500'>
                    Cancel anytime, no commitment. Sign up and get started for free 
                </Text>

                <Flex alignItems='center' justifyContent='center' p='0px'>
                    <Flex w='fit-content' px='10px' h='60px' alignItems='center' justifyContent='center' 
                        backgroundColor='white' borderRadius='8px' mt='10px' 
                        boxShadow='3px 4px 3px 2px rgba(0, 0 ,0, 0.4)'>
                        <Text mr='5px' fontWeight='500'>Monthly</Text>
                        <span id='toggleBtn' onClick={changePckg}
                            style={{position:'relative',
                            backgroundColor:pckg==='monthly'?'#5B5B5B':'#F5A08C',
                            width:'40px',height:'20px',borderRadius:'999px',cursor:'pointer'}}>
                            <span 
                                style={{backgroundColor:'white',height:'15px',width:'15px',transition:'.2s ease',
                                borderRadius:'50%',position:'absolute',
                                left:pckg==='monthly'?'2px':'22px',top:'2px'}}>
                            </span>
                        </span>
                        <Text mx='5px' fontWeight='500'>Yearly</Text>
                        <span style={{backgroundColor:'#F5A08C',height:'20px',fontWeight:"500",
                        width:'fit-content',padding:'5px',color:'white',borderRadius:'4px'}}>
                        -20%
                        </span>
                    </Flex>
                    
                </Flex>

                <Flex  justifyContent='center' px='10px' flexWrap='wrap' mt='20px'>
                    <Box id='plan_box'  p='10px' m='10px' mt='60px'>
                        <Text fontSize='21px' fontWeight='700' m={0}>Free</Text>
                        <Text textAlign='center' m={0} fontWeight='600' fontSize='33px'>
                        <sup style={{fontSize:"16px"}}>$</sup>{` ${prices.free*(100-discount)/100}` }
                        <span style={{fontSize:"18px",color:'#515982'}}>/mo</span>
                       
                        </Text>
                        {pckg==='yearly'?<Text textAlign='center' m={0} fontWeight='600' fontSize='33px'>
                        <sup style={{fontSize:"16px"}}>$</sup>{` ${(prices.free*(100-discount)/100)*12}` }
                        <span style={{fontSize:"18px",color:'#515982'}}>/year</span>
                       
                        </Text>:null}
                        <Text fontSize='13px' textAlign='center' fontWeight='500'>
                        Free plan to see if we're a good fit
                        </Text>
                        <Flex m={0} textAlign='center' fontSize='14px' mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> API Access
                        </Flex>
                        <Flex m={0} textAlign='center' fontSize='14px'  mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> 20 API Credits
                        </Flex>
                        <Flex m={0} mt={3} textAlign='center' fontSize='14px'  mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> 1 Credit per call
                        </Flex>
                        <Flex m={0} textAlign='center' fontSize='14px'  mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> 
                            <span>
                            <sup style={{fontSize:"10px",fontWeight:'500',color:'#12827C'}}>$</sup>
                            <span style={{fontSize:"13px",fontWeight:'500',color:'#12827C'}}>00.015 </span>
                             per additional credit</span>
                        </Flex>
                        <Text m={0} textAlign='center'></Text>
                        <Text m={0} textAlign='center'></Text>
                        <Link href={makeRequest('free')}>
                            <Box id='startBtn'>
                                <Text fontSize='14px' m={0} fontWeight='500' >Get Started</Text>
                                <Text fontSize='10px' m={0} > 1 month free. No credit card required</Text>

                            </Box>
                            
                        </Link>
                      
                    </Box>
                    <Box id='plan_box'  p='10px' m='10px' mt='60px'>
                        <Text fontSize='21px' fontWeight='700' m={0}>Basic</Text>
                        <Text textAlign='center' m={0} fontWeight='600' fontSize='33px'>
                        <sup style={{fontSize:"16px"}}>$</sup>{` ${prices.basic*(100-discount)/100}` }
                        <span style={{fontSize:"18px",color:'#515982'}}>/mo</span>
                       
                        </Text>
                        {pckg==='yearly'?<Text textAlign='center' m={0} fontWeight='600' fontSize='33px'>
                        <sup style={{fontSize:"16px"}}>$</sup>{` ${(prices.basic*(100-discount)/100)*12}` }
                        <span style={{fontSize:"18px",color:'#515982'}}>/year</span>
                       
                        </Text>:null}
                        
                        <Text fontSize='13px' textAlign='center' fontWeight='500'>
                        Basic LinkedIn data for research/lead generation.
                        </Text>
                        <Flex m={0} textAlign='center' fontSize='14px' mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> API Access
                        </Flex>
                        <Flex m={0} textAlign='center' fontSize='14px' mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> 3K API Credits / mo
                        </Flex>
                        <Flex m={0} mt={3} textAlign='center' fontSize='14px' mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> 1 Credit per call
                        </Flex>
                        <Flex m={0} textAlign='center' fontSize='14px'  mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> 
                            <span>
                            <sup style={{fontSize:"10px",fontWeight:'500',color:'#12827C'}}>$</sup>
                            <span style={{fontSize:"13px",fontWeight:'500',color:'#12827C'}}>00.013 </span>
                             per additional credit</span>
                        </Flex>
                        <Text m={0} textAlign='center'></Text>
                        <Text m={0} textAlign='center'></Text>

                        <Link href={makeRequest('basic')}>
                            <Box id='startBtn'>
                                <Text fontSize='14px' m={0} fontWeight='500' >Get Started</Text>
                                <Text fontSize='10px' m={0} > 1 month free. No credit card required</Text>

                            </Box>
                            
                        </Link>
                    </Box>
                    <Box id='plan_box'  p='10px' m='10px' mt='60px'
                    borderLeft='#F5A08C 1.5px solid' borderRight='#F5A08C 1.5px solid' 
                    borderBottom='#F5A08C 1.5px solid'>
                        <Text fontSize='21px' fontWeight='700' m={0}>Plus</Text>
                        <Text textAlign='center' m={0} fontWeight='600' fontSize='33px'>
                        <sup style={{fontSize:"16px"}}>$</sup>{` ${prices.plus*(100-discount)/100}` }
                        <span style={{fontSize:"18px",color:'#515982'}}>/mo</span>
                       
                        </Text>
                        {pckg==='yearly'?<Text textAlign='center' m={0} fontWeight='600' fontSize='33px'>
                        <sup style={{fontSize:"16px"}}>$</sup>{` ${(prices.plus*(100-discount)/100)*12}` }
                        <span style={{fontSize:"18px",color:'#515982'}}>/year</span>
                       
                        </Text>:null}
                        <Text fontSize='13px' textAlign='center' fontWeight='500'>
                        Moderate LinkedIn data for lead generation and analysis.
                        </Text>
                        <Flex m={0} textAlign='center' fontSize='14px' mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> API Access
                        </Flex>
                        <Flex m={0} textAlign='center' fontSize='14px' mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> 10K API Credits / mo
                        </Flex>
                        <Flex m={0} mt={3} textAlign='center' fontSize='14px' mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> 1 Credit per call
                        </Flex>
                        <Flex m={0} textAlign='center' fontSize='14px'  mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> 
                            <span>
                            <sup style={{fontSize:"10px",fontWeight:'500',color:'#12827C'}}>$</sup>
                            <span style={{fontSize:"13px",fontWeight:'500',color:'#12827C'}}>00.010 </span>
                             per additional credit</span>
                        </Flex>
                        <Text m={0} textAlign='center'></Text>
                        <Text m={0} textAlign='center'></Text>

                        <Link href={makeRequest('plus')}>
                            <Box id='startBtn'>
                                <Text fontSize='14px' m={0} fontWeight='500' >Get Started</Text>
                                <Text fontSize='10px' m={0} > 1 month free. No credit card required</Text>

                            </Box>
                            
                        </Link>

                        <Flex h='50px' w='304px' position='absolute' borderTopRadius='10px' color='white'
                        top='-50px' backgroundColor='#F5A08C' left='-2px' justifyContent='center' alignItems='center'>
                            <Text fontWeight='500' fontSize='18px'>Most Popular</Text>
                        </Flex>
                    </Box>
                    <Box id='plan_box'  p='10px' m='10px' mt='60px'>
                        <Text fontSize='21px' fontWeight='700' m={0}>Pro</Text>
                        <Text textAlign='center' m={0} fontWeight='600' fontSize='33px'>
                        <sup style={{fontSize:"16px"}}>$</sup>{` ${prices.pro*(100-discount)/100}` }
                        <span style={{fontSize:"18px",color:'#515982'}}>/mo</span>
                       
                        </Text>
                        {pckg==='yearly'?<Text textAlign='center' m={0} fontWeight='600' fontSize='33px'>
                        <sup style={{fontSize:"16px"}}>$</sup>{` ${(prices.pro*(100-discount)/100)*12}` }
                        <span style={{fontSize:"18px",color:'#515982'}}>/year</span>
                       
                        </Text>:null}
                        <Text fontSize='13px' textAlign='center' fontWeight='500'>
                            Advanced LinkedIn data extraction and analysis with moderate concurrency.
                        </Text>
                        <Flex m={0} textAlign='center' fontSize='14px' mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> API Access
                        </Flex>
                        <Flex  m={0} textAlign='center' fontSize='14px' mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> 40K API Credits / mo
                        </Flex>
                        <Flex m={0} mt={3} textAlign='center' fontSize='14px' mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> 1 Credit per call
                        </Flex>
                        <Flex m={0} textAlign='center' fontSize='14px'  mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> 
                            <span>
                            <sup style={{fontSize:"10px",fontWeight:'500',color:'#12827C'}}>$</sup>
                            <span style={{fontSize:"13px",fontWeight:'500',color:'#12827C'}}>00.008 </span>
                             per additional credit</span>
                        </Flex>
                        <Text m={0} textAlign='center'></Text>
                        <Text m={0} textAlign='center'></Text>

                        <Link href={makeRequest('pro')}>
                            <Box id='startBtn'>
                                <Text fontSize='14px' m={0} fontWeight='500' >Get Started</Text>
                                <Text fontSize='10px' m={0} > 1 month free. No credit card required</Text>

                            </Box>
                            
                        </Link>
                    </Box>
                    <Box id='plan_box'  p='10px' m='10px' mt='60px'>
                        <Text fontSize='21px' fontWeight='700' m={0}>Business</Text>
                        <Text textAlign='center' m={0} fontWeight='600' fontSize='33px'>
                        <sup style={{fontSize:"16px"}}>$</sup>{` ${prices.business*(100-discount)/100}` }
                        <span style={{fontSize:"18px",color:'#515982'}}>/mo</span>
                       
                        </Text>
                        {pckg==='yearly'?<Text textAlign='center' m={0} fontWeight='600' fontSize='33px'>
                        <sup style={{fontSize:"16px"}}>$</sup>{` ${(prices.business*(100-discount)/100)*12}` }
                        <span style={{fontSize:"18px",color:'#515982'}}>/year</span>
                       
                        </Text>:null}
                        <Text fontSize='13px' textAlign='center' fontWeight='500'>
                            Advanced LinkedIn data extraction and analysis with high concurrency.
                        </Text>
                        <Flex m={0} textAlign='center' fontSize='14px' mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> API Access
                        </Flex>
                        <Flex m={0} textAlign='center' fontSize='14px' mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> 150K API Credits / mo
                        </Flex>
                        <Flex m={0} mt={3} textAlign='center' fontSize='14px' mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> 1 Credit per call
                        </Flex>
                        <Flex m={0} textAlign='center' fontSize='14px'  mb='10px'>
                            <span style={{color:'#12827C',width:'100px'}}>&#10004;</span> 
                            <span>
                            <sup style={{fontSize:"10px",fontWeight:'500',color:'#12827C'}}>$</sup>
                            <span style={{fontSize:"13px",fontWeight:'500',color:'#12827C'}}>00.008 </span>
                             per additional credit</span>
                        </Flex>
                        <Text m={0} textAlign='center'></Text>
                        <Text m={0} textAlign='center'></Text>

                        <Link href={makeRequest('business')}>
                            <Box id='startBtn'>
                                <Text fontSize='14px' m={0} fontWeight='500' >Get Started</Text>
                                <Text fontSize='10px' m={0} > 1 month free. No credit card required</Text>

                            </Box>
                            
                        </Link>
                    </Box>
                </Flex> 
            </Box>
            <Footer/>
        </Box>
    )

}



export default Pricing;