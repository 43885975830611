import {
    Box, Flex,Text,Button, Table,Thead,Tbody,Tr,Th,Td
  } from "@chakra-ui/react"
import { Link } from "react-router-dom"

import DocItem from "./DocItem"

const SearchServicesDocs=()=>{
    return(
        <Box p='10px'>
            <Box px='15px' id='profiles' mb='20px'>
                <Text fontSize='22px' fontWeight='500'>Search Services on LinkedIn</Text>
                <span style={{backgroundColor:'#EDEDED',padding:'5px',borderRadius:'5px' }}>
                        <span style={{marginRight:'10px',fontWeight:'500' }}>POST</span>
                        <span>https://api.apedata.net/v1/search/services</span>
                </span>
            </Box>

            <Box id='people' px='15px'>
                <Box>
                    <Text fontSize='14px' fontWeight='500' >HEADERS</Text>
                    <Flex justifyContent='start' mb='50px'>
                        <Box w='90%' backgroundColor='#EBF1F5' minWidth='300px' 
                         pt='10px'>
                            <DocItem name='X-API-KEY' required={true} 
                                explanation='Your api key'
                            />
                        </Box>
                    </Flex>
                </Box>

                <Box>
                    <Text fontSize='14px' fontWeight='500' mb={5}>POST BODY</Text>
                    <Flex justifyContent='start' mb='50px'>
                        <Box w='90%' backgroundColor='#EBF1F5' minWidth='300px' pb='25px' pt='10px'>
                            <DocItem name='total_results' type='number' Default={10}
                                explanation='Maximum length of results to be retrieved'
                            />
                            <DocItem name='start' type='number' Default={'0'}
                                explanation='Index to start searching from. If total_results is set to 
                                10, you will have to set start value to 11 to get the second page.'
                            />
                            <DocItem name='keywords' type='string'
                                explanation="Keywords to base search on."
                            />
                            <DocItem name='service_categories' type='array of string'
                                explanation={`List of service category urn Ids to filter the results.
                                \nYou can get a service name urn Id from the service category urn endpoint in the search urns category`}
                            />
                            <DocItem name='languages' type='array of strings'
                                explanation="List of languages spoken by the service providers"
                                example='["English","French"]'
                            />
                            <DocItem name='countries' type='array of strings'
                                explanation=
                                {`A list of urn Ids of the countries of the service provider.
                                \nGet a country's urn id from the location endpoint in the search Urns category`}
                                example='["103644278","101165590"]'
                            />
                        </Box>
                    </Flex>
                </Box>
                
            </Box>

        </Box>
    )
}

// To utilize these 
//                     endpoints, users are required to make an HTTP POST request and provide 
//                     the API key obtained from ApeData. Additionally, users must have a 
//                     positive credit balance in order to access this data.



export default SearchServicesDocs