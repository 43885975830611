import {Box,Flex,Text,Image
  } from "@chakra-ui/react"
  

  import profImage from '../Images/icons8-users-100.png'
  import compImage from '../Images/icons8-company-100.png'
  import searchImage from '../Images/icons8-search-100.png'
  import salesImage from '../Images/icons8-sales-performance-100.png'

  import eventsImage from '../Images/icons8-event-100.png'
  import jobImage from '../Images/icons8-jobs-100.png'
  import postsImage from '../Images/icons8-chat-100.png'

import './Home.css'

const SecondSection=()=>{
    return(
        <Box classname='secondSection' justifyContent='space-between'
         px='50px' py='20px' border='1px solid white' paddingBottom='80px'>
                <Text textAlign='center' fontSize='2rem' fontWeight='bold'>API Features</Text>
                <Flex flexFlow='wrap' justifyContent='space-around' >
                    <Box border='1px solid white' id='featureBox'
                      h='fit-content'>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                            <Image src ={profImage} boxSize='100px'/>
                        </Box>
                        <Box id='featureTextBox'>
                            <Text fontWeight='bold'>#1. People's Profile Data</Text>
                            <Text>
                                Obtain real-time, up-to-date public personal data of LinkedIn 
                                users with our scalable API, covering the entire LinkedIn network.
                            </Text>
                        </Box>

                    </Box>

                    <Box border='1px solid white' id='featureBox'
                      h='fit-content'>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                            <Image src ={compImage} boxSize='100px'/>
                        </Box>
                        <Box id='featureTextBox'>
                            <Text fontWeight='bold'>#2. Companies Data</Text>
                            <Text>
                                Retrieve comprehensive company profile data using our API, 
                                encompassing the entire LinkedIn corporate landscape.
                            </Text>
                        </Box>

                    </Box>
                    
                    <Box id='featureBox'
                    border='1px solid white' h='fit-content'>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                            <Image src ={searchImage} boxSize='100px'/>
                        </Box>
                        <Box id='featureTextBox'>
                            <Text fontWeight='bold'>#3. People  & Company Search Data</Text>
                            <Text>
                                Effortlessly search and discover people and companies by their industry
                                name,location, occupations and more with our API, streamlining your 
                                LinkedIn exploration.
                            </Text>
                        </Box>

                    </Box>

                    <Box id='featureBox'
                    border='1px solid white'  h='fit-content'>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                            <Image src ={postsImage} boxSize='100px'/>
                        </Box>
                        <Box id='featureTextBox'>
                            <Text fontWeight='bold'>#4. LinkedIn Posts Data</Text>
                            <Text>
                                Gain valuable insights from accessing a wealth of real-time 
                                LinkedIn posts data with our API. Analyze trends, sentiments, and engagement 
                                metrics to drive more effective social media campaigns.
                            </Text>
                        </Box>
                    </Box>

                    <Box id='featureBox'
                    border='1px solid white'  h='fit-content'>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                            <Image src ={jobImage} boxSize='100px'/>
                        </Box>
                        <Box id='featureTextBox'>
                            <Text fontWeight='bold'>#5. Job Postings Data</Text>
                            <Text>
                                Retrieve detailed information about job postings on LinkedIn with our 
                                API to enhance your recruitment strategy. Access job titles, descriptions, 
                                requirements, and company details to optimize your hiring processes
                            </Text>
                        </Box>
                    </Box>

                    <Box id='featureBox'
                    border='1px solid white'  h='fit-content'>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                            <Image src ={eventsImage} boxSize='100px'/>
                        </Box>
                        <Box id='featureTextBox'>
                            <Text fontWeight='bold'>#6. Upcoming Events Data</Text>
                            <Text>
                                Gain real-time data on LinkedIn events, including webinars, conferences, and industry 
                                meetups. Stay informed on industry events and identify networking opportunities 
                                to stay ahead.
                            </Text>
                        </Box>
                    </Box>

                    {/* <Box id='featureBox'
                    border='1px solid white'  h='fit-content'>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                            <Image src ={salesImage} boxSize='100px'/>
                        </Box>
                        <Box id='featureTextBox'>
                            <Text fontWeight='bold'>#4. Company Sales Data</Text>
                            <Text>
                                inform your strategy, understand audience preferences, 
                                and 
                            </Text>
                            <Text>
                                inform your strategy, understand audience preferences, 
                                and 
                            </Text>
                            <Text>
                                Discover a new world of insight on companies by accessing info such as revenues,
                                hirings and more with our API
                            </Text>
                        </Box>
                    </Box> */}

                </Flex>
                
        </Box>
    )
}


export default SecondSection;