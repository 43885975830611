import {
    Box, Flex,Text,Button, Table,Thead,Tbody,Tr,Th,Td
  } from "@chakra-ui/react"
import { Link } from "react-router-dom"

const ProfilesIntro=()=>{
    return(
        <Box p='10px'>
            <Box px='15px' id='profiles' mb='20px'>
                <Text fontSize='25px' fontWeight='500'>People and Company Data </Text>
                <Text fontSize='14px'>
                    The "People & Company Data" endpoint group provides comprehensive  
                    up-to-date information about individuals and companies listed on LinkedIn 
                </Text>
            </Box>

            <Box id='people' px='15px'>
                <Text fontSize='20px' fontWeight='500' >Endpoints in this category</Text>
                <Text fontSize='14px'>
                   1. Profile details
                </Text>
                <Text fontSize='14px'>
                   2. Company details
                </Text>
            </Box>

        </Box>
    )
}

// To utilize these 
//                     endpoints, users are required to make an HTTP POST request and provide 
//                     the API key obtained from ApeData. Additionally, users must have a 
//                     positive credit balance in order to access this data.



export default ProfilesIntro