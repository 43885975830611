import {
    Box,
    Text,
    Image,
    Link,
    useDisclosure ,
    Flex,
    Button
    
  } from "@chakra-ui/react"

  import { useState,useEffect } from "react";

  import Index from '../components/app/Index'
  import {Pie,CreditBar,Usage_Bar} from '../components/Graph/graph'
  import './pages.css'

  import planImage from '../components/Images/icons8-plan-96.png'


  const Credits=()=>{

    const planPrices={
        free:0,
        basic:40,
        plus:100,
        pro:350,
        business:1200

    }
    const planCredits={
        free:`50 (one-time)`,
        basic:`3k/month`,
        plus:`10k/month`,
        pro:`45k/month`,
        business:`150k/month`
    }

    function formatDate(epoch) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        if(epoch===0){
            return 'Never'
        }else{
            const date = new Date(epoch); // Convert seconds to milliseconds
      
            const month = months[date.getMonth()];
            const day = date.getDate();
            const year = date.getFullYear();
        
            return `${month} ${day}, ${year}`;
        }
        
      }

    const [planDets,setPlanDets]=useState({
        plan_type:'-',
        amount:0,
        dated:0,
        expiry:0
    })

    console.log(planDets);

    let webToken
    const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Check if the cookie starts with the specified name
            if (cookie.startsWith('webToken=')) {
                webToken=cookie.substring('webToken='.length)
            }
        }
    const [a_plan,setAPlan] =useState('-')
    const[c_balance,setCBalance]=useState('-')

    const getConfigs=()=>{
        return new Promise(async(resolve, reject) => {
            // let url=`http://localhost:3005/api/data/config`
            // let url=`https://api.apedata.net/api/data/config`

            const url= process.env.REACT_APP_SERVER_HOST+'/api/data/config';
            
            try {
                fetch(url,{
                    method:'GET',
                    headers:{
                        "Content-Type":'application/json',
                        "authorization":`Bearer ${webToken}`}
                }).then(async res=>{
                    
                    let response=await res.json()
                    console.log(response);
                    const {account_plan,credit_balance,recent_payment}=response.data
                    const {payment_due,for_plan,payment_on}=recent_payment
                    setAPlan(account_plan)
                    setCBalance(credit_balance)
                    setPlanDets({
                        plan_type:account_plan,
                        amount:planPrices[account_plan],
                        expiry:payment_due,
                        dated:payment_on
                    })
                    
                    resolve(account_plan);
                })
            } catch (error) {
                
            }
        })
    }

    const [longform,setLongform]=useState(false)
    function formatNumberToK(number) {
        if(longform){
            return number.toLocaleString()
        }else{
            if (number >= 1000) {
                const roundedNumber = Math.round(number / 100) / 10; // Round to 1 decimal place
                return roundedNumber + 'k';
            } else {
                return number.toString();
            }
        }
        
    }

    useEffect(()=>{
        getConfigs()
    },[])

    return (
        <Box>
            <Index selected='credits' title='API KEYS'
            mainPart={
            <Box position='relative'>
                <Box h='80px' borderBottom='1px solid grey' display='flex' alignItems='center' px='10px'>
                    <Text fontSize='15px' fontWeight='600'>CREDITS & BILLING</Text>
                </Box>
                <Box p='10px' backgroundColor='#F1F2F5' minHeight='500px'>

                {/* <Box backgroundColor='white' borderRadius='6px' p='10px' px='20px'>
                            <Text fontWeight='500'  m='0px'>
                            Plan Summary
                            </Text>

                            <Flex alignItems='center' justifyContent='center' flexWrap='wrap'>
                                <Box w='20%' minW='150px'>
                                    <Text fontSize='14px' textAlign='center' fontWeight='500'>
                                    Current Plan
                                    </Text>
                                    
                                    <Text textAlign='center' fontWeight='600'>{a_plan.toUpperCase()}</Text>
                                </Box>
                                <Box w='20%' minW='150px'>
                                    <Text fontSize='14px' textAlign='center' fontWeight='500'>
                                    Price
                                    </Text>
                                    
                                    <Text textAlign='center' fontWeight='600'>{a_plan.toUpperCase()}</Text>
                                </Box>
                                <Box w='20%' minW='150px'>
                                    <Text fontSize='14px' textAlign='center' fontWeight='500'>
                                    Credit Balance
                                    </Text>
                                    <Text textAlign='center' fontWeight='600'>{c_balance}</Text>
                                    
                                </Box>
                                
                            </Flex>

                         </Box> */}
                    
                    <Flex id='credit_billing_div' justifyContent='start' flexWrap='wrap'>
                        <Box  m='10px' id='billing_div' >
                            <Text fontWeight='600' fontSize='18px' m={1} mb='5px'>My Plan</Text>
                            <Box backgroundColor='#E5E5FC' w='300px' borderRadius='5px'  p='10px' h='200px'>
                                
                                <Flex h='80px'  alignItems='center' >
                                    <Image src={planImage} boxSize='50px' mr='10px'/>
                                    <Text textAlign='center' m='0px' ml='10px' 
                                    fontWeight='600' fontSize='1.2rem' color='#BE6015'>
                                    {`${planDets.plan_type.toUpperCase()}`}
                                    </Text>
                                </Flex>
                                
                                
                                <Flex justifyContent='space-between'>
                                    <Text m={3}fontSize='13px'>Charge </Text>
                                    <Text  m={3} fontSize='13px' fontWeight='bold'>
                                    $ {`${planDets.amount}/month`}</Text>
                                </Flex>
                                <Flex justifyContent='space-between'>
                                    <Text  m={3} fontSize='13px'>Credit amount</Text>
                                    <Text  m={3} fontSize='13px' fontWeight='bold'>
                                    {`${planCredits[planDets.plan_type]}`}
                                    </Text>
                                </Flex>
                                <Flex justifyContent='space-between'>
                                    <Text  m={3}fontSize='13px'>Next Date</Text>
                                    <Text  m={3} fontSize='13px' fontWeight='bold'>
                                        {`${formatDate(planDets.expiry)}`}
                                    </Text>
                                </Flex>
                                <Flex justifyContent='end' mt='10px'>
                                    <Button h='30px'mr='5px' backgroundColor='#3A3B5E' borderRadius='5px'
                                    outline='none' border='none' color='white' cursor='not-allowed'
                                    >
                                    Cancel plan
                                    </Button>

                                    <Link href={`/app/purchase?current=${planDets.plan_type}`}>
                                        <Button ml='5px' outline='none' border='none' backgroundColor='#28C058'
                                        borderRadius='5px' cursor='pointer' color='white' h='30px'>
                                        Upgrade plan
                                        </Button>

                                    </Link>
                                    
                                </Flex>
                            </Box>
                        </Box>
                        <Box  m='10px' id='credit_div'>
                            <Text fontWeight='600' fontSize='18px' m={1} mb='5px'>Credits</Text>
                            <Box backgroundColor='#E5E5FC' w='300px' borderRadius='5px'  p='10px' h='200px'>
                                <Text textAlign='center' fontWeight='500' fontSize='14px'>
                                Credits Balance
                                </Text>
                                <Text textAlign='center' fontWeight='600' fontSize='18px' m={0}
                                onMouseEnter={()=>setLongform(true)} onMouseLeave={()=>setLongform(false)}>
                                {formatNumberToK(c_balance)}
                                </Text>

                                <Flex mt='10px' justifyContent='center' alignItems='center'>
                                    <Button mr='10px' border='1px solid #0C67F8' backgroundColor='#E5E5FC' outline='none' h='30px'
                                    color='#0C67F8' borderRadius='5px' cursor='pointer' > 
                                    + Add Credits</Button>
                                </Flex>
                            </Box>
                            
                        </Box>
                        


                        
                    </Flex>
                    
                </Box>
            </Box>
            }

            />

        </Box>
    )

  }


  export default Credits