import {
    Box, Flex,Text,Button, Table,Thead,Tbody,Tr,Th,Td
  } from "@chakra-ui/react"
import { Link } from "react-router-dom"

import DocItem from "./DocItem"

const SearchJobsDocs=()=>{
    return(
        <Box p='10px'>
            <Box px='15px' id='profiles' mb='20px'>
                <Text fontSize='22px' fontWeight='500'>Search Jobs on LinkedIn </Text>
                <span style={{backgroundColor:'#EDEDED',padding:'5px',borderRadius:'5px' }}>
                        <span style={{marginRight:'10px',fontWeight:'500' }}>POST</span>
                        <span>https://api.apedata.net/v1/search/jobs</span>
                </span>
            </Box>

            <Box id='people' px='15px'>
                <Box>
                    <Text fontSize='14px' fontWeight='500' >HEADERS</Text>
                    <Flex justifyContent='start' mb='50px'>
                        <Box w='90%' backgroundColor='#EBF1F5' minWidth='300px' 
                         pt='10px'>
                            <DocItem name='X-API-KEY' required={true} 
                                explanation='Your api key'
                            />
                        </Box>
                    </Flex>
                </Box>

                <Box>
                    <Text fontSize='14px' fontWeight='500' mb={5}>POST BODY</Text>
                    <Flex justifyContent='start' mb='50px'>
                        <Box w='90%' backgroundColor='#EBF1F5' minWidth='300px' pb='25px' pt='10px'>
                            <DocItem name='total_results' type='number' Default={10}
                                explanation='Maximum length of results to be retrieved'
                            />
                            <DocItem name='start' type='number' Default={'0'}
                                explanation='Index to start searching from. If total_results is set to 
                                10, you will have to set start value to 11 to get the second page.'
                            />
                            <DocItem name='keywords' type='string'
                                explanation="Keywords to base search on. The keywords will be used to check for a match with the job title"
                            />
                            <DocItem name='companies' type='array of strings'
                                explanation={`A list of urnIds of the companies that the job listings should be retrieved from.
                                \nTo get a particular company's urn Id, make use of the company urns endpoint in the search urns category`}
                                example='["1009","1441"]'
                            />
                            <DocItem name='experience_levels' type={`array strings-limited to:\n"intern", "entry",  "associate",  "mid",   "senior", "executive" only`}
                                explanation='A list of strings for defining the job experience levels'
                                example='["entry","mid","associate"]'
                            />
                            <DocItem name='job_types' type={`array strings-limited to:\n"full-time", "part-time",  "contract",  "temporary",   "internship", "volunteer" and "other" only`}
                                explanation=' A list of the job contract types'
                                example='["full-time","part-time","contract"]'
                            />
                             {/* <DocItem name='job_title' type='array of strings'
                                explanation={`A list of valid job title strings.
                                \nTo get the list of valid job titles, use the get job titles endpoint`}
                               
                            /> */}
                            <DocItem name='industries' type='array of strings'
                                explanation={`A list of industry names where the users are working in.
                                \nYou can retrieve valid industry names and their urn Ids from the industry endpoint in search urns category  `}
                            />
                            <DocItem name='remote_filters' type={`array of strings - limited to\n"onsite", "remote", or "hybrid"`}
                                explanation='Filter for remote jobs, onsite ,remote or hybrid.'
                                example='["onsite"]'
                            />
                            <DocItem name='listed_at' type='number'
                                explanation='Maximum number of hours passed since job posting. "24" will filter job postings posted in last 24 hours'
                                Default='24'
                            />
                             
                        </Box>
                    </Flex>
                </Box>
                
            </Box>

        </Box>
    )
}

// To utilize these 
//                     endpoints, users are required to make an HTTP POST request and provide 
//                     the API key obtained from ApeData. Additionally, users must have a 
//                     positive credit balance in order to access this data.



export default SearchJobsDocs