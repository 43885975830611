import {
    Box,Text ,Flex,Button,Checkbox,
    Input,Link,Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CircularProgress
  } from "@chakra-ui/react"

import Logo from "../components/Logo/Logo";
import './pages.css'
import { useState ,useEffect} from "react";
import { useSearchParams  } from 'react-router-dom'



const Purchase=()=>{
    // (100-discount)/100)
    const [showLoader,setShowLoader]=useState(false)
    let webToken
    const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Check if the cookie starts with the specified name
            if (cookie.startsWith('webToken=')) {
                webToken=cookie.substring('webToken='.length)
            }
        }

    if(!webToken){
        const location = new URL(window.location.href)
        const redirect_to=location.pathname+location.search
        let whole_path='/app/login?'+new URLSearchParams({redirect_to})
        window.location.href = whole_path;
    }

    const [pckg,setPckg]=useState('monthly')
    const [userSel,setUserSel]=useState('free')

    const [discount,setDiscount]=useState(0)
    const [prices,setPrices]=useState({
        free:0,
        basic:40,
        plus:100,
        pro:350,
        business:1200
    })

    const [showConfirm,setShowConfirm]=useState(false)

    const handleStartTrial=(e)=>{
        e.preventDefault()
        const selectedBtn=document.querySelector('#radioButton:checked')
        if(selectedBtn){
            console.log(selectedBtn.value,pckg);
          
            setShowConfirm('confirm') 
        }else{
           
          
            setShowConfirm('none') 
            
            
        }
    }
    const changePckg=()=>{
        if(pckg==='monthly'){
            setPckg('yearly')
            setDiscount(20)
        }else{
            setPckg('monthly')
            setDiscount(0)
        }
        
    } 
    
    const [searchParams] = useSearchParams();
    let currentElement
   

    const subscribeThem=()=>{
        // let url=`http://localhost:3005/api/payments/subscribe`
            
            const payment={type:'trial',dated:(new Date().getTime()),amount:0}
            const new_plan={plan_package:pckg,plan_type:userSel}
            const payment_details={payment,new_plan}
            // let url=`https://api.apedata.net/api/payments/subscribe`
            let url=process.env.REACT_APP_SERVER_HOST+'/api/payments/subscribe'
            setShowLoader(true)
            fetch(url,{
                method:'POST',
                headers:{
                    "Content-Type":'application/json',
                    "authorization":`Bearer ${webToken}`},
                    body:JSON.stringify({payment_details})
            }).then(async res=>{
                setShowLoader(false)
                let resp=await res.json()
                if(res.status==200){
                    setShowConfirm("success")

                }else{
                    console.log(resp);
                    setShowConfirm('error')
                }
               
                
            })
            .catch(err=>{
                console.log(err.message);
                setShowConfirm('error')
            })

    }
    const setParams=()=>{


        const radios=document.querySelectorAll("input[type='radio']")
        const plan_box_subs=document.querySelectorAll('#plan_box_sub')
        let pack=searchParams.get('package')
        let pn=searchParams.get('plan')
        let current=searchParams.get(['current'])
       ;
        if(pack && pack!==pckg){
            changePckg()
        }
        if(pn){
            radios.forEach((item,indx)=>{
                console.log(indx);
                if(item.value===pn){
                    // item.click()
                    setUserSel(pn)
                    plan_box_subs[indx].click()
                    plan_box_subs[indx].classList.add('selected')
                }
            })
        }
        if(current){
            radios.forEach((item,indx)=>{
                console.log(indx);
                if(item.value===current){
                    // item.click()
                    // plan_box_subs[indx].click()
                    // plan_box_subs[indx].classList.add('selected')
                    plan_box_subs[indx].style.opacity='0.5'
                    currentElement=plan_box_subs[indx]
                    // plan_box_subs[indx].removeEventListener('click')
                }
            })
        }
        
        
        
        // if(plan_type){
        //     changePckg(plan_type)

        // }
        // console.log(chosen,plan_type);
    }
    
    function formatDate(epoch) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        if(epoch===0){
            return 'Never'
        }else{
            const date = new Date(epoch); // Convert seconds to milliseconds
      
            const month = months[date.getMonth()];
            const day = date.getDate();
            const year = date.getFullYear();
        
            return `${month} ${day}, ${year}`;
        }
        
      }

     

    useEffect(()=>{

        // document.addEventListener('click', function(event) {
        //     event.preventDefault()
        //     // Check if the clicked element is not within the div
        //     const confirmChild=document.querySelector('#confirmChild')
        //     console.log(confirmChild);
        //     if (confirmChild && !confirmChild.contains(event.target)) {
        //         setShowConfirm(false)
        //     }
           
            

        // });

        const radios=document.querySelectorAll("input[type='radio']")
        const plan_box_subs=document.querySelectorAll('#plan_box_sub')
        plan_box_subs.forEach(item=>{
            item.addEventListener('click',e=>{
                console.log(currentElement);
                if(!currentElement || !currentElement.contains(e.target)){
                    let itemRadio=item.querySelector("input[type='radio']")
                    const value=itemRadio.value
                    itemRadio.click()
                }
                
            })
        })
        setParams()

        

        radios.forEach(elm=>{
            elm.addEventListener('change',e=>{
                console.log(currentElement);
                if(!currentElement || !currentElement.contains(e.target)){
                    plan_box_subs.forEach(elm=>{
                        elm.classList.remove('selected')
                    })
                    radios.forEach(ite=>{
                        
                        if(ite===e.target){
                           ite.checked=true
                           let parent = ite.parentNode;
                           while(parent){
                            if (parent.id === 'plan_box_sub') {
                                parent.classList.add('selected')
                                setUserSel(ite.value)
                                break
                            }
                            parent = parent.parentNode;
                           }
                        }else{
                            ite.checked=false
                        }
                    })
                }
                
                // console.log(e.target.checked);
            })
        })
        
    },[])
    return(
        <Box>
            <Flex px='20px' alignItems='center'>
                <Logo colored={true}/>
                <Text ml='10px'color='#55565C' fontWeight='500' fontSize='14px'>PLANS</Text>
            </Flex>
            <Box backgroundColor='#E1E1E1' minH='800px' h='fit-content' pb='40px'>
                {/* <Flex alignItems='center' justifyContent='center' >
                    <Flex borderRadius='6px' h='50px' mt='50px' className="purchaseBox" 
                    alignItems='center' justifyContent='center'>
                        <Flex backgroundColor='#F5A08C'  alignItems='center' justifyContent='center'
                        borderLeftRadius='10px' w='35%'>
                            <Text color='white' fontWeight='500'>Pick your plan</Text>
                        </Flex>
                        <Flex backgroundColor='white' w='70%'  borderRightRadius='10px'
                        alignItems='center' px='20px'>
                            <Text fontWeight='500'>Start your 1 month trial, now!</Text>
                        </Flex>
                    </Flex>
                </Flex> */}

                <Flex alignItems='center' justifyContent='center'>
                    <Flex className="purchaseBox" alignItems='center' >
                        <Flex backgroundColor='#F5A08C' alignItems='center' justifyContent='center' 
                        h='25px' w='25px' style={{borderRadius:'50%',color:'white'}}>1</Flex>
                        <Text fontWeight='500' ml='10px' >Confirm you preferred plan</Text>
                    </Flex>
                    
                </Flex>
                <Flex alignItems='center' justifyContent='center'>
                
                        

                </Flex>
                <Flex alignItems='center' justifyContent='center' p='0px'>
                    <Flex w='fit-content' px='10px' h='60px' alignItems='center' justifyContent='center' 
                        backgroundColor='white' borderRadius='8px' mt='10px' 
                        boxShadow='3px 4px 3px 2px rgba(0, 0 ,0, 0.4)'>
                        <Text mr='5px' fontWeight='500'>Monthly</Text>
                        <span id='toggleBtn' onClick={changePckg}
                            style={{position:'relative',
                            backgroundColor:pckg==='monthly'?'#5B5B5B':'#F5A08C',
                            width:'40px',height:'20px',borderRadius:'999px',cursor:'pointer'}}>
                            <span 
                                style={{backgroundColor:'white',height:'15px',width:'15px',transition:'.2s ease',
                                borderRadius:'50%',position:'absolute',
                                left:pckg==='monthly'?'2px':'22px',top:'2px'}}>
                            </span>
                        </span>
                        <Text mx='5px' fontWeight='500'>Yearly</Text>
                        <span style={{backgroundColor:'#F5A08C',height:'20px',fontWeight:"500",
                        width:'fit-content',padding:'5px',color:'white',borderRadius:'4px'}}>
                        -20%
                        </span>
                    </Flex>
                    
                </Flex>
                <Flex alignItems='center' justifyContent='center'>
                <Flex className="purchaseBox" alignItems='center'  justifyContent='center'
                flexWrap='wrap'>
                    <Box  m='10px' backgroundColor='white' borderRadius='6px' id='plan_box_sub' h='120px'
                    borderBottom='3px solid red' >
                            <Flex alignItems='center' >
                                <Input id="radioButton" value='free'
                            width='20px' height='20px' type='radio' backgroundColor='red' accentColor='red'/>
                                <span style={{
                                    fontSize:'16px',fontWeight:"500",
                                    position:'relative',top:'2px',marginLeft:"10px"}}>Free</span>
                            </Flex>
                            <Text m={0} ml='36px' my='6px'  fontSize='13px'color='gray'>20 credits</Text>
                            <Text m={0} ml='36px' my='6px'  fontSize='13px'color='gray'>$0 / month</Text>
                             
                        

                    </Box>
                    <Box w='45%' m='10px' backgroundColor='white' borderRadius='6px' id='plan_box_sub' h='120px'>
                    <Flex alignItems='center'>
                                    <Input id="radioButton" value='basic'
                                width='20px' height='20px' type='radio'/>
                                    <span style={{
                                        fontSize:'16px',fontWeight:"500",
                                        position:'relative',top:'px',marginLeft:"10px"}}>Basic</span>
                            </Flex>
                            <Text m={0} ml='36px' my='6px'  fontSize='13px'color='gray'>3k Credits</Text>
                            <Text m={0} ml='36px' my='6px' fontSize='13px'color='gray'>1-month free trial</Text>
                            <Text m={0} ml='36px' my='6px'  fontSize='13px'color='gray'>
                            then $
                            <strong style={{color:"gray",fontSize:'14px',fontWeight:'700'}}>
                             {` ${prices.basic*(100-discount)/100}` }
                            </strong>
                            
                            / month 
                            {pckg==='yearly'?
                            <span>  ( $
                            <strong style={{color:"gray",fontSize:'14px',fontWeight:'700',marginLeft:'5px'}}>
                               {` ${prices.basic*(100-discount)/100*12}` }
                            </strong> / year)
                            </span>
                            :null}
                            </Text>  

                    </Box>
                    <Box w='45%' m='10px' backgroundColor='white' borderRadius='6px' id='plan_box_sub' h='120px'>
                        <Flex alignItems='center' >
                                    <Input id="radioButton" value='plus'
                                width='20px' height='20px' type='radio' backgroundColor='red' accentColor='red'/>
                                <span style={{
                                    fontSize:'16px',fontWeight:"500",
                                    position:'relative',top:'2px',marginLeft:"10px"}}>Plus</span>
                            </Flex>
                            <Text m={0} ml='36px' my='6px'  fontSize='13px'color='gray'>10k Credits</Text>
                            <Text m={0} ml='36px' my='6px' fontSize='13px'color='gray'>1-month free trial</Text>
                            <Text m={0} ml='36px' my='6px'  fontSize='13px'color='gray'>
                            then $ 
                            <strong style={{color:"gray",fontSize:'14px',fontWeight:'700'}}>
                             {` ${prices.plus*(100-discount)/100}` }
                            </strong>
                            / month
                            {pckg==='yearly'?
                            <span>  ( $
                            <strong style={{color:"gray",fontSize:'14px',fontWeight:'700',marginLeft:'5px'}}>
                               {` ${prices.plus*(100-discount)/100*12}` }
                            </strong> / year)
                            </span>
                            :null}</Text>
                    </Box>
                    <Box w='45%' m='10px' backgroundColor='white' borderRadius='6px' id='plan_box_sub' h='120px'>
                    <Flex alignItems='center'>
                                    <Input id="radioButton" value='pro'
                                width='20px' height='20px' type='radio' backgroundColor='red' accentColor='red'/>
                                    <span style={{
                                        fontSize:'16px',fontWeight:"500",
                                        position:'relative',top:'2px',marginLeft:"10px"}}>Pro</span>
                            </Flex>
                            <Text m={0} ml='36px' my='6px'  fontSize='13px'color='gray'>40k Credits</Text>
                            <Text m={0} ml='36px' my='6px' fontSize='13px'color='gray'>1-month free trial</Text>
                            <Text m={0} ml='36px' my='6px'  fontSize='13px'color='gray'>
                            then $
                            <strong style={{color:"gray",fontSize:'14px',fontWeight:'700'}}>
                             {` ${prices.pro*(100-discount)/100}` }
                            </strong>
                            / month
                            {pckg==='yearly'?
                            <span>  ( $
                            <strong style={{color:"gray",fontSize:'14px',fontWeight:'700',marginLeft:'5px'}}>
                               {` ${prices.pro*(100-discount)/100*12}` }
                            </strong> / year)
                            </span>
                            :null}</Text>
                    </Box>
                    <Box w='45%' m='10px' backgroundColor='white' borderRadius='6px' id='plan_box_sub' h='120px'>
                        <Flex alignItems='center'>
                                    <Input id="radioButton" selected value='business'
                                width='20px' height='20px' type='radio' backgroundColor='red' accentColor='red'/>
                                    <span style={{
                                        fontSize:'16px',fontWeight:"500",
                                        position:'relative',top:'2px',marginLeft:"10px"}}>Business</span>
                            </Flex>
                            <Text m={0} ml='36px' my='6px'  fontSize='13px'color='gray'>150k Credits</Text>
                            <Text m={0} ml='36px' my='6px' fontSize='13px'color='gray'>1-month free trial</Text>
                            <Text m={0} ml='36px' my='6px'  fontSize='13px'color='gray'>
                            then $
                            <strong style={{color:"gray",fontSize:'14px',fontWeight:'700'}}>
                             {` ${prices.business*(100-discount)/100}` }
                            </strong>
                            / month
                            {pckg==='yearly'?
                            <span>  ( $
                            <strong style={{color:"gray",fontSize:'14px',fontWeight:'700',marginLeft:'5px'}}>
                               {` ${prices.business*(100-discount)/100*12}` }
                            </strong> / year)
                            </span>
                            :null}
                            </Text>
                    </Box>
                    

                </Flex>
                
                </Flex>
                <Flex   h='300px' w='' justifyContent='center' mt='20px' >
                        <Box  id='finalBox' h='200px'  backgroundColor='white' borderRadius='6px' p='12px'>
                                <Flex alignItems='center' justifyContent='space-between' borderBottom='1px solid gray' px='5px'>
                                    <Text fontSize='13px'>Monthly after free trial</Text>
                                    <Text fontSize='15px'>$10000</Text>
                                </Flex>
                                <Flex alignItems='center' justifyContent='space-between' borderBottom='1px solid gray' px='5px'>
                                    <Text fontSize='15px' fontWeight='500'>Today's total</Text>
                                    <Text fontSize='15px' fontWeight='500'>$0</Text>
                                </Flex>
                                <Flex alignItems='center' justifyContent='space-between' 
                                borderBottom='1px solid gray' px='5px'>
                                    <Text fontSize='14px' fontWeight='400'>
                                        Your free trial begins on 
                                        <strong style={{fontSize:'15px',fontWeight:'500'}}>
                                        { ` ${formatDate(new Date().getTime())} `} 
                                        </strong>
                                        and will end on  
                                        <strong style={{fontSize:'15px',fontWeight:'500'}}>
                                        { ` ${formatDate(new Date().getTime()+(30*24*60*60*1000))}.  `} 
                                        </strong>
                                        We will send an email reminder 
                                        <strong style={{fontSize:'15px',fontWeight:'600'}}> 7 days before </strong>
                                       the trial ends.
                                    </Text>
                                </Flex>

                               

                        </Box>

                        
                    </Flex>

                    <Flex justifyContent='center' alignItems='center' mt='0px' position='relative' top='-20px'>
                            <Button m='0px' outline='none' border='none' w='120px' cursor='pointer'
                            borderRadius='99px' onClick={handleStartTrial}
                            backgroundColor='#1A73E8' color='white' h='40px'>Start Trial</Button>
                            {showConfirm==='none'?
                            <Flex position='fixed' top='0px' zIndex='100' id='confirmPrompt' 
                            w='100%' h='100%' justifyContent='center' alignItems='center'
                            backgroundColor='rgba(0, 0, 0, 0.5)'>
                               
                                <Box h='120px' w='330px' backgroundColor='white' borderRadius='6px' p='10px' 
                                px='20px' id='confirmChild'>
                                        <Text fontWeight='500'>You haven't picked a plan !</Text>
                                        <Text fontWeight='500'>Please pick a valid plan to continue</Text>
                                        <Button border='none' outline='none' onClick={()=>setShowConfirm(false)}
                                        backgroundColor='#E1E1E1' h='30px' w='50px'>
                                        Close
                                        </Button>
                                        
                                </Box>
                                

                                
                        </Flex>
                        :showConfirm==='confirm'?
                        <Flex position='fixed' top='0px' zIndex='100' id='confirmPrompt' w='100%' h='100%'
                        justifyContent='center' alignItems='center' backgroundColor='rgba(0, 0, 0, 0.5)'>
                                <Box h='140px' w='330px' backgroundColor='white' borderRadius='6px' 
                                p='10px' px='20px' id='confirmChild'>
                                            <Text  m={3} fontWeight='500'>Confirm Subscription</Text>
                                            <Text m={4} fontSize='13px'>
                                                You are subscribing and starting your trial period
                                                for 
                                                <strong 
                                                style={{fontWeight:'600',fontSize:'14px',color:'black',marginLeft:'5px',marginRight:'5px'}}>
                                                {`${userSel} plan ( ${pckg} )`} 
                                                </strong>
                                                 

                                            </Text>
                                            <Text m={3} fontSize='13px'>
                                            Your trial period will end on
                                            <strong 
                                                style={{fontWeight:'600',fontSize:'14px',color:'black',marginLeft:'5px',marginRight:'5px'}}>
                                                 {`${formatDate(new Date().getTime()+(30*24*3600*1000))}`} 
                                                </strong>
                                            
                                            </Text>

                                            <Flex justifyContent='space-around' mt='10px'>
                                            <Button border='none' outline='none' onClick={subscribeThem}
                                                backgroundColor='#1FB141' h='35px' w='90px' 
                                                cursor='pointer' color='white' borderRadius='3px'>
                                                Subscribe
                                                </Button>
                                                <Button border='none' outline='none' onClick={()=>setShowConfirm(false)}
                                                    backgroundColor='#E1E1E1' h='35px' w='90px' borderRadius='3px'>
                                                    Close
                                                    </Button>
                                            </Flex>
                                            {showLoader?<Box position='absolute' top='50%' right='50%' zIndex='999'>
                                                <CircularProgress isIndeterminate color='#0F6972' />
                                            </Box>:null}

                                    </Box>  
                        </Flex>
                        :showConfirm==='error'?
                        <Flex position='fixed' top='0px' zIndex='100' id='confirmPrompt' w='100%' h='100%'
                        justifyContent='center' alignItems='center' backgroundColor='rgba(0, 0, 0, 0.5)'>
                                <Box h='140px' w='330px' backgroundColor='white' borderRadius='6px' 
                                p='10px' px='20px' id='confirmChild'>
                                            <Text  m={3} fontWeight='500'>Unknown error</Text>
                                            <Text m={4} fontSize='13px'>
                                                Action could not be perfomed
                                            </Text>
                                            {showLoader?<Box position='absolute' top='50%' right='50%' zIndex='999'>
                                                <CircularProgress isIndeterminate color='#0F6972' />
                                            </Box>:null}
                                            <Flex justifyContent='space-around' mt='40px' >
                                            <Link border='none' href="/app/dashboard" backgroundColor='#F5A08C' 
                                            p='3px' px='10px' color='white'>
                                                Go to dashboard
                                                </Link>
                                                <Button border='none' outline='none' onClick={()=>setShowConfirm(false)}
                                                    backgroundColor='#E1E1E1' h='35px' w='90px' borderRadius='3px' >
                                                    Close
                                                    </Button>
                                            </Flex>

                                    </Box>  
                        </Flex>
                        :showConfirm==='success'?
                        <Flex position='fixed' top='0px' zIndex='100' id='confirmPrompt' w='100%' h='100%'
                        justifyContent='center' alignItems='center' backgroundColor='rgba(0, 0, 0, 0.5)'>
                                <Box h='140px' w='330px' backgroundColor='white' borderRadius='6px' 
                                p='10px' px='20px' id='confirmChild'>
                                            <Text  m={3} fontWeight='500'>Success</Text>
                                            <Text m={4} fontSize='13px'>
                                                Congratulations, you've been upgraded
                                            </Text>
                                            {showLoader?<Box position='absolute' top='50%' right='50%' zIndex='999'>
                                                <CircularProgress isIndeterminate color='#0F6972' />
                                            </Box>:null}
                                            <Flex justifyContent='space-around' mt='40px' >
                                            <Link border='none' href="/app/dashboard" backgroundColor='#F5A08C' 
                                            p='3px' px='10px' color='white'>
                                                Go to dashboard
                                                </Link>
                                                <Button border='none' outline='none' onClick={()=>setShowConfirm(false)}
                                                    backgroundColor='#E1E1E1' h='35px' w='90px' borderRadius='3px' >
                                                    Close
                                                    </Button>
                                            </Flex>

                                    </Box>  
                        </Flex>:null}
                            
                    </Flex>

                    {/* <Flex  mt='10px' alignItems='center' justifyContent='center' backgroundColor='#E1E1E1' >
                        <Box backgroundColor='white' p='20px' h='250px' id='finalBox' borderRadius='6px' >
                            <Text>Frequently asked questions</Text>
                            <Flex flexWrap='wrap' alignItems='center' justifyContent='center'>
                                <Box m='5px' minWidth='150px'>
                                    <Text m={0} fontSize='13px' fontWeight='500'>
                                        Will I be charged during my free trial?
                                    </Text>
                                </Box>
                                <Box m='5px' minWidth='150px'>
                                    <Text  m={0} fontSize='13px' fontWeight='500'>
                                        How can I cancel during my free trial?
                                    </Text>
                                </Box>
                                <Box m='5px' minWidth='150px'>
                                    <Text  m={0} fontSize='13px' fontWeight='500'>
                                        How can I cancel during my free trial?
                                    </Text>
                                </Box>
                            </Flex>
                        </Box>
                    </Flex> */}
                    
                    <Flex justifyContent='center' alignItems='center' mt='150px'>
                        <Logo/>
                        <Link href='/privacy' ml='10px' fontSize='14px'>Privacy policy</Link>
                    </Flex>

                    
            </Box>
        </Box>
    )
}

export default Purchase;