import {
    Box, Flex,Text,Button, Table,Thead,Tbody,Tr,Th,Td
  } from "@chakra-ui/react"
import { Link } from "react-router-dom"

const UrnSearchesIntro=()=>{
    return(
        <Box p='10px'>
            <Box px='15px' id='profiles' mb='20px'>
                <Text fontSize='25px' fontWeight='500'>Search Urn Ids</Text>
                <Text fontSize='14px'>
                    The "Search Urn Ids" endpoint group provides the urn Ids for various entities
                    on LinkedIn. This includes industry, locations and services urnIds
                </Text>
                <Text fontSize='14px'>
                    Using the "Urn Id" of an entity provides a better, more exact match 
                </Text>
            </Box>

            <Box id='people' px='15px'>
                <Text fontSize='20px' fontWeight='500' >Endpoints in this category</Text>
                <Text fontSize='14px'>
                   1. Search Location Urn
                </Text>
                <Text fontSize='14px'>
                   2. Search Industry Urn
                </Text>
                <Text fontSize='14px'>
                   3.LinkedIn posts search
                </Text>
                <Text fontSize='14px'>
                   4.LinkedIn jobs search
                </Text>
                <Text fontSize='14px'>
                   5.Events search
                </Text>
                <Text fontSize='14px'>
                   6. Services search
                </Text>
            </Box>

        </Box>
    )
}

// To utilize these 
//                     endpoints, users are required to make an HTTP POST request and provide 
//                     the API key obtained from ApeData. Additionally, users must have a 
//                     positive credit balance in order to access this data.



export default UrnSearchesIntro