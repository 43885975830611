import {
    Box,Text,Link,Flex
  } from "@chakra-ui/react"

  import { FiLogOut } from "react-icons/fi";


  import Index from '../components/app/Index'
  import Playground from '../components/app/Playground'

const Settings=()=>{
    return(
        <Box>
        <Index selected='settings' mainPart={
            <Box>
                <Box h='80px' borderBottom='1px solid grey' display='flex' alignItems='center' px='10px'>
                    <Text fontSize='15px' fontWeight='600'>SETTINGS</Text>
                </Box>
                <Box px='10px'>
                    <Text fontSize='14px' fontWeight='500'>General Info</Text>
                    <Box px='5px'>
                        <Text fontSize='13px' fontWeight='500' color='grey'>EMAIL</Text>
                        <span style={{
                            border:'1px solid #DEDEE0',
                            borderRadius:'5px',
                            padding:'10px',
                            paddingLeft:'10px',
                            fontSize:'14px',
                            fontWeight:'500'

                        }}>glenomondi9@gmail.com</span><br/>
                        
                        
                 
                    </Box>
                   
                    <Text fontSize='14px' fontWeight='500' mt='50px'>Account</Text>
                    <Box px='10px'>
                        <Link href='/app/passwordRequest' target="_blank" fontWeight='500' 
                        color='grey' fontSize='13px' textDecoration="underline">RESET PASSWORD</Link>
                       
                    </Box>
                    
                </Box>
            </Box>
            }/>
        </Box>
    )
}

export default Settings;