import {
    Box, Flex,Text,Button, Table,Thead,Tbody,Tr,Th,Td
  } from "@chakra-ui/react"
import { Link } from "react-router-dom"

import DocItem from "./DocItem"

const SearchPostsDocs=()=>{
    return(
        <Box p='10px'>
            <Box px='15px' id='profiles' mb='20px'>
                <Text fontSize='22px' fontWeight='500'>Search LinkedIn Posts </Text>
                <span style={{backgroundColor:'#EDEDED',padding:'5px',borderRadius:'5px' }}>
                        <span style={{marginRight:'10px',fontWeight:'500' }}>POST</span>
                        <span>https://api.apedata.net/v1/search/posts</span>
                </span>
            </Box>

            <Box id='people' px='15px'>
                <Box>
                    <Text fontSize='14px' fontWeight='500' >HEADERS</Text>
                    <Flex justifyContent='start' mb='50px'>
                        <Box w='90%' backgroundColor='#EBF1F5' minWidth='300px' 
                         pt='10px'>
                            <DocItem name='X-API-KEY' required={true} 
                                explanation='Your api key'
                            />
                        </Box>
                    </Flex>
                </Box>

                <Box>
                    <Text fontSize='14px' fontWeight='500' mb={5}>POST BODY</Text>
                    <Flex justifyContent='start' mb='50px'>
                        <Box w='90%' backgroundColor='#EBF1F5' minWidth='300px' pb='25px' pt='10px'>
                            <DocItem name='total_results' type='number' Default={10}
                                explanation='Maximum length of results to be retrieved'
                            />
                            <DocItem name='start' type='number' Default={'0'}
                                explanation='Index to start searching from. If total_results is set to 
                                10, you will have to set start value to 11 to get the second page.'
                            />
                            <DocItem name='keywords' type='string'
                                explanation="Keywords to base search on. The keywords will be used to check for a match with the
                                posts title and content"
                            />
                            <DocItem name='author_job_title_keyword' type='string'
                                explanation={`The keywords of the job title of the posts owner. If given, posts from people whose job titles match the keywords will be retrieved`}
                                
                            />
                            <DocItem name='sort_by' type={`limited string:\nOne of "date" or "relevance" only`}
                                explanation={`A string indicating what to sort retrieved posts by.`}
                            />
                            
                            <DocItem name='date_posted' type={`limited string:\nOne of "past-day", "past-week", or "past-month" only`}
                                explanation='The time limit by which to filter the retrieved posts'
                                example='"past-day"'
                            />
                            <DocItem name='content_contains' 
                            type={`limited string : \none of "image", "video", "document", "article", "live-videos" only`}
                                explanation={`Refine the results of the posts by the type of media the post contains`}
                                example='"image"'
                            />
                             <DocItem name='from_companies' type='array of strings'
                                explanation={`A list of urn Ids of the companies to get posts from.
                                \nTo get a particular company's urnId, you can make use of the company urns endpoint in the search urns category`}
                                example='["1009","1441"]'
                            />
                            <DocItem name='companies_mentioned' type='array of strings'
                                explanation={`A list of urn Ids of the companies mentioned in the posts to be retrieved
                                \nTo get a particular company's urnId, you can make use of the company urns endpoint in the search urns category`}
                                example='["1009","1441"]'
                            />
                            <DocItem name='poster_industries' type='array of strings'
                                explanation={`A list of valid industry names where the posters work in.
                                \nYou can retrieve valid industry names and their urn Ids from the industry endpoint in search urns category  `}
                            />
                            {/* <DocItem name='people_mentioned' type='array of strings'
                                explanation={`List of entityUrns of the people mentioned in the posts.
                                \nTo get a particular profiles's entityUrns, you can make use of the get profile endpoint`}
                                
                            /> */}
                            <DocItem name='poster_companies' type='array of strings'
                                explanation={`A list of the urn Ids of the companies where the post owners work . i.e "get the posts from workers of these companies"
                                \nTo get a particular company's urn Id, make use of the company urns endpoint in the search urns category`}
                                
                            />
                            
                        </Box>
                    </Flex>
                </Box>
                
            </Box>

        </Box>
    )
}

// To utilize these 
//                     endpoints, users are required to make an HTTP POST request and provide 
//                     the API key obtained from ApeData. Additionally, users must have a 
//                     positive credit balance in order to access this data.



export default SearchPostsDocs