import './Home.css'
import {Box,Flex,Text,Button,Link,Tabs ,TabList,Tab,TabPanel,TabPanels,Stack,Code
} from "@chakra-ui/react"
  

const FirstSection=()=>{
    return(
        <Flex id='firstSection' backgroundColor='#0F6972' color='white'
        flexFlow='wrap' justifyContent='space-between' minH='500px'
        >
            <Box  id="box3">
                <Text fontSize='3.5rem' fontWeight={600} m='0px' textAlign='center'>
                    LinkedIn  
                    <small m='0px' style={{
                        fontSize:'3.5rem', color:'#F5A08C',fontWeight:'700'
                        }}> Public </small>
                    Data 
                    <small m='0px' style={{
                        fontSize:'3.5rem', color:'#F5A08C',fontWeight:'700'}}> API </small>
                </Text>
                <Text fontSize='1.2rem' fontWeight={500} textAlign='center'>
                Unlock the Power of LinkedIn: Leverage Our Enterprise-Grade, 
                Real-Time API for Fast, Stable & Affordable Public Data Insights
                </Text>

                <Flex justifyContent='center'>
                    <Link href="/app/register" cursor='pointer'>
                        <Button mr='10px'  cursor='pointer' h='40px' w='150px' border='none' color='white'
                            outline='none' backgroundColor='#F5A08C'
                            borderRadius='999px' fontWeight={500} transition='all .1s'
                             _hover={{transform:"scale(0.98)",color:'white' ,border:'2px solid #F5A08C'}}>
                        Get API Keys
                       </Button>
                    </Link>
                    <Link href="/app/playground" cursor='pointer'>
                        <Button mr='10px'  cursor='pointer' h='40px' w='150px' border='none' color='white'
                            outline='none' backgroundColor='#F5A08C'
                            borderRadius='999px' fontWeight={500} transition='all .1s'
                             _hover={{transform:"scale(0.98)",color:'white' ,border:'2px solid #F5A08C'}}>
                        Try a Live Request
                       </Button>
                    </Link>
                    {/* <Link href="#" cursor='pointer'>
                        <Button ml='5px' h='40px' backgroundColor='#0F6972' w='150px' 
                        borderRadius='999px' border='2px solid #F5A08C' color='#F5A08C'
                        cursor='pointer' outline='none' >Try It For Free</Button>
                    </Link> */}
                </Flex>
            </Box>
            <Box  borderRadius='5px' h='fit-content' minH='350px' backgroundColor='white' id="box4" p='10px'>
                <Tabs   isFitted >
                    <TabList  backgroundColor='white' h='30px' borderBottom='#E2E8F0 1px solid'>
                        <Tab w='33%' border="none" borderBottom='2px solid #E2E8F0'
                         backgroundColor='white' cursor='pointer' color='#a5a9ac'
                         _selected={{ borderBottom: '#3182CE 2px solid',color:'black' }}>
                            Python
                         </Tab>
                        <Tab w='33%'border="none" backgroundColor='white' 
                        borderBottom='2px solid #E2E8F0' cursor='pointer' color='#a5a9ac'
                        _selected={{ borderBottom: '#3182CE 2px solid',color:'black' }}>
                            Javascript
                        </Tab>
                        <Tab w='33%' border="none" backgroundColor='white' 
                        borderBottom='2px solid #E2E8F0' cursor='pointer' color='#a5a9ac'
                        _selected={{ borderBottom: '#3182CE 2px solid',color:'black' }}>
                            cURL
                        </Tab>
                    </TabList>
                    

                    <TabPanels>
                        <TabPanel>
                            <Stack direction='column' color='black' fontSize='13px' padding='10px'>
                                <Code color='#B80672' children="# Set API URL" />
                                <Code children='url = "https://api.apedata.net/v1/profile"'  />

                                <Code color='#B80672' children='# Set API key' marginTop='10px'/>
                                <Code children="headers = {"  />
                                <Code children='"X-API-KEY": "your_api_key"' mx='20px'/>
                                <Code children="}"  mx='5px'/>

                                <Code color='#B80672' children="# Set payload" marginTop='10px' />
                                <Code colorScheme='yellow' children="payload = {" />
                                <Code colorScheme='yellow' children='"profile_id": "williamhgates"' mx='20px'/>
                                <Code children="}"  mx='5px'/>

                                <Code color='#B80672' children='# Get Response' marginTop='10px'  />
                                <Code colorScheme='red' children="resp = requests.post(url, json=payload, headers=headers)" />
                            </Stack>
                        </TabPanel>
                        <TabPanel>
                            <Stack direction='column' color='black' fontSize='13px' padding='10px'>
                                <Code color='#B80672' children="// Set API URL"  />
                                <Code children='const url = "https://api.apedata.net/v1/profile"'  />

                                <Code color='#B80672'  children='// Set API key' marginTop='15px' />
                                <Code children={`const headers = {`}  />
                                <Code children={`'X-API-KEY': 'your_api_key'`} mx='20px' />
                                <Code children={`'Content-Type': 'application/json'`} mx='20px' />
                                <Code children={`}`} mx='5px' />

                                <Code color='#B80672'  children="// Set payload" marginTop='15px' />
                                <Code colorScheme='yellow' children="const payload = {'profile_id': 'williamhgates'}" />
                                
                                <Code color='#B80672' children='// Get Response' marginTop='15px'  />
                                <Code  children="const resp = await fetch(url,{" />
                                <Code  children="method:'POST'," mx='20px' />
                                <Code  children="headers:headers," mx='20px' />
                                <Code  children="body:JSON.stringify(payload)" mx='20px' />
                                <Code  children="}" mx='5px' />
\                            </Stack>
                        </TabPanel>
                        <TabPanel>
                            <Stack direction='column' color='black' fontSize='13px' padding='10px'>
                                <Code  children="curl -X POST \ " />
                                <Code color='#B80672' children='-H "X-API-KEY: your_api_key" \ ' mx='10px' />
                                <Code color='#B80672' children='-H "Content-Type: application/json" \ ' mx='10px' />
                                <Code color='#B80672' children={`-d '{"profile_id": "williamhgates"}' `} mx='10px' />
                                <Code  children=" https://api.apedata.net/v1/profile" /> 
                            </Stack>
                        </TabPanel>
                        
                    </TabPanels>
                </Tabs>
            </Box>
        </Flex>
    )
}


export default FirstSection;