import {Box,Flex,Text,Input,Link,CircularProgress,Button } from "@chakra-ui/react"
import { useState,useEffect } from "react";

import Index from '../components/app/Index'

import './pages.css'
import '../components/app/app.css'
const Dashboard=()=>{

    let webToken
    const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Check if the cookie starts with the specified name
            if (cookie.startsWith('webToken=')) {
                webToken=cookie.substring('webToken='.length)
            }
        }

        
    const [a_plan,setAPlan] =useState('-')
    const [a_email,setAEmail] =useState(false)
    const[c_balance,setCBalance]=useState('-')

    const [longform,setLongform]=useState(false)
    function formatNumberToK(number) {
        if(longform){
            return number.toLocaleString()
        }else{
            if (number >= 1000) {
                const roundedNumber = Math.round(number / 100) / 10; // Round to 1 decimal place
                return roundedNumber + 'k';
            } else {
                return number.toString();
            }
        }
        
    }


    const getConfigs=()=>{
        return new Promise(async(resolve, reject) => {
            // let url=`http://localhost:3005/api/data/config`
            // let url=`https://api.apedata.net/api/data/config`
            const url= process.env.REACT_APP_SERVER_HOST+'/api/data/config';
            
            try {
                fetch(url,{
                    method:'GET',
                    headers:{
                        "Content-Type":'application/json',
                        "authorization":`Bearer ${webToken}`}
                }).then(async res=>{
                    
                    let response=await res.json()
                    console.log(response);
                    const {account_plan,credit_balance,email}=response.data
                    setAEmail(email)
                    setAPlan(account_plan)
                    setCBalance(credit_balance)
                    
                    resolve(account_plan);
                })
            } catch (error) {
                
            }
        })
    }

    useEffect(()=>{
        getConfigs()
    },[])

    return(
        <Box minH='500px'>
            <Index selected='dashboard' title='DASHBOARD'
                mainPart={
                    <Box position='relative'>
                        <Box h='80px' borderBottom='1px solid grey' 
                        display='flex' alignItems='center' px='20px' backgroundColor='white'>
                            <Text fontSize='15px' fontWeight='600'>DASHBOARD</Text>
                        </Box>

                        <Box backgroundColor='#F1F2F5' h='fit-content' minHeight='550px' 
                         p='20px' pb='50px' >

                        <Box backgroundColor='white' borderRadius='6px' p='10px' px='20px' mb='20px'>
                            <Text fontWeight='500'  m='0px'>
                            Account
                            </Text>

                            <Flex alignItems='center'flexWrap='wrap'>
                                <Text fontSize='14px' fontWeight='500'>
                                    Email :
                                    </Text>
                                    <Text ml='10px' fontWeight='400'>{a_email}</Text>
                            </Flex>

                         </Box >

                         <Box backgroundColor='white' borderRadius='6px' p='10px' px='20px'>
                            <Text fontWeight='500'  m='0px'>
                            Usage Summary
                            </Text>

                            <Flex alignItems='center' justifyContent='center' flexWrap='wrap'>
                                <Box w='50%' minW='200px'>
                                    <Text fontSize='14px' textAlign='center' fontWeight='500'>
                                    Credit Balance
                                    </Text>
                                    <Text textAlign='center' fontWeight='600' 
                                    onMouseEnter={()=>setLongform(true)} onMouseLeave={()=>setLongform(false)}
                                    >{formatNumberToK(c_balance)}
                                    </Text>
                                    
                                </Box>
                                <Box w='50%' minW='200px'>
                                    <Text fontSize='14px' textAlign='center' fontWeight='500'>
                                    Current Plan
                                    </Text>
                                    
                                    <Text textAlign='center' fontWeight='600'>{a_plan.toUpperCase()}</Text>
                                </Box>
                            </Flex>

                         </Box>

                         <Box backgroundColor='white' borderRadius='6px' p='10px' px='20px' mt='30px'>
                            <Text fontWeight='500'  m='0px'>
                            Quick Actions
                            </Text>

                            <Flex alignItems='center' justifyContent='center' flexWrap='wrap'>
                                <Box w='30%' minW='250px' p='5px'>
                                    <Text fontSize='14px' textAlign='center' fontWeight='500'>
                                    Billings &  Payments
                                    </Text>
                                    <Text fontSize='14px' textAlign='center' fontWeight='400'>
                                        Manage subscriptions and purchases
                                    </Text>
                                    <Text textAlign='center'>
                                        <Link href='/app/credits' >
                                            <Button border='none' outline='none'color='white' cursor='pointer'
                                            w='100px' h='40px' borderRadius='3px' backgroundColor='#1B5842'>
                                                GO
                                            </Button>
                                        </Link>
                                    </Text>
                                   
                                    
                                    
                                </Box>
                                <Box w='30%' minW='250px' p='5px'>
                                    <Text fontSize='14px' textAlign='center' fontWeight='500'>
                                    API Keys 
                                    </Text>
                                    
                                    <Text fontSize='14px' textAlign='center' fontWeight='400'>
                                        Delete and Create API Keys
                                    </Text>
                                    <Text textAlign='center'>
                                        <Link href='/app/keys' >
                                            <Button border='none' outline='none'color='white' cursor='pointer'
                                            w='100px' h='40px' borderRadius='3px' backgroundColor='#1B5842'>
                                                GO
                                            </Button>
                                        </Link>
                                    </Text>
                                </Box>
                                <Box w='30%' minW='250px' p='5px'>
                                    <Text fontSize='14px' textAlign='center' fontWeight='500'>
                                    Documentation
                                    </Text>
                                    <Text fontSize='14px' textAlign='center' fontWeight='400'>
                                       Access API Documentation
                                    </Text>
                                    <Text textAlign='center'>
                                        <Link href='/docs' >
                                            <Button border='none' outline='none'color='white' cursor='pointer'
                                            w='100px' h='40px' borderRadius='3px' backgroundColor='#1B5842'>
                                                GO
                                            </Button>
                                        </Link>
                                    </Text>
                                </Box>
                            </Flex>
                         </Box>

                         

                        </Box>
                    </Box>
                }
            />
        </Box>
    )

}

export default Dashboard