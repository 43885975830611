import {
    Box,Text,Flex,Button
  } from "@chakra-ui/react"

  import './graph.css'
  import Chart from "chart.js/auto";
  import { Doughnut } from 'react-chartjs-2';
  import { useEffect } from "react"


export const Pie=({f,s,t})=>{

    const data = {
        labels: [
          'Used',
          'Available',
        ],
        datasets: [{
          label: 'Credit Usage',
          data: [ 300,100],
          backgroundColor: [
            '#EA5052',
            '#00A8D6',
          ],
          borderWidth:1,
          weight:10,
          borderJoinStyle:'round',
          hoverOffset: 4
        }]
      };

   
    return(  
    <Flex alignItems='center' justifyContent='center' m='0px'>
       <Doughnut data={data} />

    </Flex>  
    )
}

export const  UsageBar=({labeLs,labeL,daTa})=>{

    useEffect(()=>{

        const ctx = document.getElementById('myChart');
        new Chart(ctx, {
            type: 'line',
            data: {
              labels: labeLs,
              datasets: [{
                label: labeL,
                data: daTa,
                borderWidth: 1
              }]
            },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
          });
        // const values = [30, 40, 30]; // Values for each slice (e.g., percentages)
        // const total = values.reduce((acc, val) => acc + val, 0); // Calculate total

        // const slices = document.querySelectorAll('.slice');

        // let cumulativePercent = 0;

        // slices.forEach((slice, i) => {
        // const percent = (values[i] / total) * 100;
        // slice.style.transform = `rotate(${cumulativePercent}deg)`;
        // slice.style.clipPath = `polygon(50% 50%, 100% 0%, 100% 100%)`;
        // cumulativePercent += percent;
        // });
    },[])

    return(
        <Box w='100%' py='20' pb='50px'>
                <canvas style={{width:'100%',height:'400px'}} id="myChart"></canvas>
        </Box>
    )

}

export const  CreditBar=()=>{

    return(
        <Box>
            <Flex>
                <Flex alignItems='center' mr='20px'>
                    <Box w='15px' h='15px' backgroundColor='#EA5052' mr='8px'></Box>
                    <Text fontSize='12px' fontWeight='500' m={0}>Used</Text>
                </Flex>

                <Flex alignItems='center'>
                    <Box w='15px' h='15px' backgroundColor='#08B356' mr='8px'></Box>
                    <Text fontSize='12px' fontWeight='500' m={0}>Available</Text>
                </Flex>
                
            </Flex>
            <Flex alignItems='center'  minW='320px'>
                <Flex h='25px' mt='10px' w='100%' mr='10px' >
                    <Box w='30%' h='100%' borderLeftRadius='4px' backgroundColor='#EA5052' transition='all 5s ease out'></Box>
                    <Box w='70%' h='100%' borderRightRadius='4px' backgroundColor='#08B356' transition='all 5s ease out'></Box>
                </Flex>
                <Text fontSize='14px' fontWeight='500'>30/100 Credits</Text>

            </Flex>
            <Flex mt='10px'>
                <Button mr='10px' border='1px solid #0C67F8' backgroundColor='#E5E5FC' outline='none' h='30px'
                color='#0C67F8' borderRadius='5px' cursor='pointer' > 
                + Add Credits</Button>
                {/* <Button outline='none' border='none' backgroundColor='#0C67F8' color='white' 
                borderRadius='5px' cursor='pointer'>
                Increase Limit</Button> */}
            </Flex>

            
        </Box>
    )

}



