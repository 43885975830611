import {
    Box,
    Text,
    Image,
    Link,
    CircularProgress,
    Flex,
    Button,
    Input
    
  } from "@chakra-ui/react"

  import Navbar from "../components/Navbar/Navbar";
  import Footer from "../components/Footer/Footer";
  import { useState,useEffect } from "react";

  
  import './pages.css'


  const Contact=()=>{

    const handleSubmit=(e)=>{
        e.preventDefault()
        const message=document.querySelector('textarea').value
        const email=document.querySelector('#email').value
        const name=document.querySelector('#name').value
        const subject=document.querySelector('#subject').value

        // let url='http://localhost:3005/api/message/new'
        // let url='https://api.apedata.net/api/message/new'
        const url= process.env.REACT_APP_SERVER_HOST+'/api/message/new';

        setShowLoader(true)
        fetch(url,{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({name,email,subject,message})
        }).then(res=>{
            setShowLoader(false)
            if(res.status===200){
                setShowSuccess('success')
            }else{
                setShowSuccess('error')
            }
        })
        .catch(err=>{
            setShowSuccess('error')
        })

        console.log(message,email,name,subject);
    }
    const [showLoader,setShowLoader]=useState(false)
    const [showSuccess,setShowSuccess]=useState('normal')
    return(
        <Box>
            <Navbar light={true}/>
            
        <Box backgroundColor='#F1B980' pb='100px'>
        <Text textAlign='center' fontWeight='700' fontSize='3rem' m='0px' color='#0F6972'>
       Get In Touch</Text>
       {showSuccess==='normal'?<form onSubmit={handleSubmit}
            style={{padding:'10px',paddingTop:'20px',paddingBottom:'50px',display:"flex",justifyContent:"center"}}>
                <Box boxShadow='2px 2px 2px 2px rgba(0,0,0,0.3)' w='350px' backgroundColor='white'
                borderRadius='6px' py='20px' pb='50px'>
                    <Flex mt='20px'justifyContent='center' >
                        <Input placeholder="Your name" w='280px' h='40px'borderRadius='5px'
                            outline='1px solid #077C76' border='none' id='name'
                        />
                    </Flex>
                    <Flex mt='20px'justifyContent='center' alignItems='center'>
                        <Input placeholder="Your email*" w='280px' required id='email'
                        type="email" h='40px'borderRadius='5px' outline='1px solid #077C76' border='none'/>
                    </Flex>
                    <Flex mt='20px'justifyContent='center' alignItems='center'>
                        <Input placeholder="Subject" w='280px' h='40px'borderRadius='5px'
                         outline='1px solid #077C76' border='none' id='subject'/>
                    </Flex>
                    <Flex mt='20px'justifyContent='center' alignItems='center'>
                        <textarea id='message' required style={{
                            width:'280px',borderRadius:'5px',
                            height:'100px',
                            outline:'1px solid #077C76' ,
                            border:'none'}} placeholder="Message">

                        </textarea >
                    </Flex>
                    <Flex mt='20px'justifyContent='center' alignItems='center'>
                        <Button w='280px' h='40px' type="submit" border='none' outline='none'
                        borderRadius='5px' backgroundColor='#077C76' color='white' cursor='pointer'>
                        Send Message
                        </Button>
                    </Flex>
                    

                </Box>
                {showLoader?<Box position='absolute' top='50%' right='50%'>
                <CircularProgress isIndeterminate color='#0F6972' />
            </Box>:null}
                

            </form>:
            showSuccess==='success'?
            <Flex justifyContent='center' alignItems='center' mt='15px'>
                <Box h='200px' w='250px' backgroundColor='white' borderRadius='6px' 
                boxShadow='2px 2px 2px 2px rgba(0,0,0,0.3)'>
                            <Text textAlign='center' fontWeight='500' fontSize='18px'>Message Sent</Text>
                            <Text textAlign='center' fontSize='3rem' m={0} color='#14A800'>	&#10004;</Text>
                            <Text textAlign='center' fontSize='13px'> We'll be getting back to you </Text>
                </Box>
            </Flex>:
            showSuccess==='error'?
            <Flex justifyContent='center' alignItems='center' mt='15px'>
                <Box h='200px' w='250px' backgroundColor='white' borderRadius='6px' 
                boxShadow='2px 2px 2px 2px rgba(0,0,0,0.3)'>
                            <Text textAlign='center' fontWeight='500' fontSize='18px'>Could not Send Message</Text>
                            <Text textAlign='center' fontSize='3rem' m={0} color='#14A800'>	&#10006;</Text>
                            <Text textAlign='center' fontSize='13px' fontWeight='500'> Please try again </Text>
                </Box>
            </Flex>:null }

        </Box>
        </Box>
    )
  }


  export default Contact